import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {moduleRouting} from './home.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';

/* BOOTSTRAP-COMPONENTS */

import { BsDatepickerModule } from 'ngx-bootstrap';

import { defineLocale } from 'ngx-bootstrap/chronos';

import { enGbLocale } from 'ngx-bootstrap/locale';

defineLocale('engb', enGbLocale);

import { DateSet } from './../provider/dateSet.pipe';
import { SearchPipe } from './../provider/search.pipe';

import { LoaderComponent } from './../loader/loader.component';



/* CUSTOM-COMPONENTS */

import { IndexComponent } from './index/index.component';
import { SearchresultComponent } from './searchresult/searchresult.component';
import { BookingComponent } from './booking/booking.component';
import { AccountComponent } from './account/account.component';
import { SuccessComponent } from './success/success.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { TermsComponent } from './terms/terms.component';
import { RatingComponent } from './rating/rating.component';

@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule,
    moduleRouting,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    BsDatepickerModule.forRoot(),
    Ng5SliderModule
  ],
  declarations: [
    IndexComponent,
    SearchresultComponent,
    BookingComponent,
    AccountComponent,
    TermsComponent,
    SuccessComponent,
    LoaderComponent,
    RatingComponent,
    DateSet,
    SearchPipe,

    ChangepasswordComponent
  
  ]
})
export class HomeModule { }
