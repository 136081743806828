import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {
  errorList = {
    'required': 'This field is required',
    'email': 'Please enter a valid email',
    'phone': 'Please enter a valid phone number',
    'password': 'Please enter a valid password',
    'minLength': 'Minimum characters length is',
    'maxLength': 'Maximum characters length is',
    'min': 'Age min start at ',
    'max': 'Age max end at ',
    'confirm': 'Password mismatch'
  };

  constructor() { }

  getErrorList() {
    return this.errorList;
  }

  getError(error: any): string {
    if (this.errorList.hasOwnProperty(error.code)) {  // check whether the error code having our definition
      return this.errorList[error.code]; // return our definition
    } else {
      return error.message;
    }
  }
}
