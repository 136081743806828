import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { WebService } from "./../../provider/web.service";
import {ExcelService} from './../../provider/excel.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {Moment} from 'moment'
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import { ValidationService } from "./../../provider/validation.service";
import { SubjectService } from "./../../provider/subject.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ticketConfig } from "../../../environments/server.config";


interface ICompany {
  id: number;
  rating: number;
  bookingId: string;
  journeyDate: string;
}
declare var Razorpay: any;
@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  FromDateBookingReport: Date;
  ToDateBookingReport: Date;
  FromDatePaymentReport: Date;
  ToDatePaymentReport: Date;
  FromDateDailyReport: Date;
  ToDateDailyReport: Date;
  bookDetails: any[];
  loginDetails: any;
  funcName: any;
  loading: boolean;
  profileSubmit: boolean;
  passwordSubmit: boolean;
  private current_page = 0;
  private current_page_rating = 0;
  private current_booking_report =0;
  private current_daily_report =0;
  private current_payment_report =0;
  public profileForm: FormGroup;
  public passwordForm: FormGroup;
  mobnumPattern = "^((\\+?)|0)?[0-9]{0,20}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  profileMsg: any;
  passwordMsg: any;
  ageDate: any;
  bookinfoData: any;
  loader: boolean;
  modalRef: BsModalRef;
  amount_error: any;
  amount: any;
  walletData: any;
  detailedError: any;
  ticketPath: any;
  ratingShow:any;
  testRating : any[];
  ratingClicked: number;
  itemIdRatingClicked: string;
  bookReportDetails: any[];
  DailyReportDetails: any[];
  PaymentyReportDetails: any[];
  createdCode:any;
  recharge_amount_error:any;
  recharge_type_error:any;
  recharge_transaction_id_error:any;
  recharge_date_error:any;
  recharge_amount:any;
  recharge_type:any;
  recharge_transaction_id:any;
  recharge_date:any;
  items: ICompany[];
  creditBalance:any=0;
  items_rating:ICompany[];
  @ViewChild("wallet") private login: TemplateRef<HTMLElement>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: WebService,
    private formBuilder: FormBuilder,
    public vs: ValidationService,
    public subjectService: SubjectService,
    private excelService:ExcelService,
    private _localeService: BsLocaleService,
    private bsmodalservice: BsModalService
  ) {
    this._localeService.use('engb');
    this.loading = false;
    this.profileSubmit = false;
    this.bookDetails = [];
    this.bookReportDetails = [];
    this.DailyReportDetails =[];
    this.PaymentyReportDetails = [];
    this.ageDate = new Date();
    this.ageDate.setFullYear(this.ageDate.getFullYear() - 12);
    console.log(this.ageDate);
    this.loader = false;
  }

  ngOnInit() {
    console.log("ticketConfig", ticketConfig);
    this.createdCode="test";
    this.ticketPath = ticketConfig;
    this.loginDetails = JSON.parse(
      this.service.getLocalStorageItem("userData")
    );
    const mydate = new Date(this.loginDetails.dob);
    this.bookingDetails();
    this.walletAmount();
    this.bookReport();
    this.dailyReport();
    this.PaymentReport();
    if (this.loginDetails.type !== "agent") {
      this.profileForm = this.formBuilder.group({
        name: [
          this.loginDetails.name,
          Validators.compose([Validators.required]),
        ],
        dob: ['', Validators.compose([Validators.required])],
        gender: [
          this.loginDetails.gender,
          Validators.compose([Validators.required]),
        ],
        // tslint:disable-next-line:max-line-length
        mob: [
          this.loginDetails.mob,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(15),
            Validators.pattern(this.mobnumPattern),
          ]),
        ],
        // tslint:disable-next-line:max-line-length
        username: [
          this.loginDetails.username,
          Validators.compose([
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(this.emailPattern),
          ]),
        ],
      });
    } else {
      this.profileForm = this.formBuilder.group({
        name: [
          this.loginDetails.name,
          Validators.compose([Validators.required]),
        ],
        // tslint:disable-next-line:max-line-length
        mob: [
          this.loginDetails.phone,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(15),
            Validators.pattern(this.mobnumPattern),
          ]),
        ],
        // tslint:disable-next-line:max-line-length
        username: [
          this.loginDetails.email_id,
          Validators.compose([
            Validators.required,
            Validators.maxLength(50),
            Validators.pattern(this.emailPattern),
          ]),
        ],
      });
    }
    this.passwordForm = this.formBuilder.group({
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      newpassword: [
        "",
        Validators.compose([Validators.required, Validators.minLength(6)]),
      ],
      cpassword: [
        "",
        Validators.compose([Validators.required]),
        this.MatchPassword.bind(this),
      ],
    });
  }

  goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  bookingDetails() {
    const sendData = {
      userId: this.loginDetails.id,
      page: this.current_page,
      type: this.loginDetails.type,
    };
    this.funcName = "bookDetails";
    this.loading = true;
    this.service.post_data(this.funcName, sendData).subscribe(
      (response) => {
        this.loading = false;
        if (response.status === "success" && response.data.length > 0) {
          this.bookDetails = this.bookDetails.concat(response.data);
          ++this.current_page;
      
        
         this.getAllUnratedBooking();
       
        } else {
          this.bookDetails =
            this.bookDetails.length > 0 ? this.bookDetails : [];
        }
     
      },
      (error) => {}
    );
  }
  getAllUnratedBooking(){
    const sendData = {
      userId: this.loginDetails.id,
      page: this.current_page_rating,
      type: this.loginDetails.type,
    };
    this.funcName = "CustomerUnRatedBooking";
    this.loading = true;
    this.service.post_data(this.funcName, sendData).subscribe(
      (response) => {
        this.loading = false;
        if (response.status === "success" && response.data.length > 0) {
        
          ++this.current_page_rating;
          var r=0; this.items = [];
          response.data.forEach((val,key) => {
          if(val.is_rated =='0'){
            this.items.push({
              'id': r,
              'rating': 0,
              'bookingId':val.bookingId,
              'journeyDate':val.travelDate,
          }); 

          }
       
         r++; })
         console.log('this.items',this.items)
        } 
     
      },
      (error) => {}
    );

  }
  check_time(time: string, type: string) {
    const timeArray = time.split(" - ");
    if (type === "start") {
      return timeArray[0];
    } else {
      return timeArray[1];
    }
  }

  onScroll() {
    console.log("scrolled down!!");
    this.bookingDetails();
  }

  profileProcess() {
    this.profileSubmit = true;
    if (this.profileForm.valid) {
      const pass_data = this.profileForm.value;
      const This = this;
      let postData: any;
      if (this.loginDetails.type !== "agent") {
        const dob = this.formatDate(pass_data.dob);
        postData = {
          name: pass_data.name,
          gender: pass_data.gender,
          dob: dob,
          username: pass_data.username,
          mob: pass_data.mob,
          userId: this.loginDetails.id,
        };

        this.funcName = "profile_update";
      } else {
        postData = {
          name: pass_data.name,
          email_id: pass_data.username,
          phone: pass_data.mob,
          userId: this.loginDetails.id,
        };

        this.funcName = "agent_update";
      }

      this.loading = true;
      this.service.post_data(this.funcName, postData).subscribe(
        (response) => {
          this.loading = false;
          if (response.status === "success") {
            this.profileMsg =
              '<small class="text-info">' + response.message + "</smal>";
            if (this.loginDetails.type !== "agent") {
              response.data.type = "user";
            } else {
              response.data.type = "agent";
            }
            this.service.setLocalStorageItem(
              "userData",
              JSON.stringify(response.data)
            );
            this.subjectService.sendLoginData("logged_in");
          } else {
            this.profileMsg =
              '<small class="text-danger">' + response.message + "</smal>";
          }
          setTimeout(function () {
            This.profileMsg = "";
          }, 3000);
        },
        (error) => {
          this.profileMsg =
            '<small class="text-danger"> Something went wrong. Feel free to contact us .</smal>';
        }
      );
    }
  }

  formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [year, month, day].join("-");
  }

  MatchPassword(val: any) {
    return new Promise((resolve) => {
      if (val.value === this.passwordForm.value.newpassword) {
        resolve(null);
      } else {
        resolve({ match: false });
      }
    });
  }

  passwordProcess() {
    this.passwordSubmit = true;
    if (this.passwordForm.valid) {
      const password_data = this.passwordForm.value;
      const This = this;

      const postData = {
        password: password_data.password,
        newpassword: password_data.newpassword,
        userId: this.loginDetails.id,
        type: this.loginDetails.type,
      };

      this.funcName = "password_update";

      this.loading = true;
      this.service.post_data(this.funcName, postData).subscribe(
        (response) => {
          this.loading = false;
          if (response.status === "success") {
            this.passwordMsg =
              '<small class="text-info">' + response.message + "</smal>";
            this.service.setLocalStorageItem(
              "userData",
              JSON.stringify(response.data)
            );
          } else {
            this.passwordMsg =
              '<small class="text-danger">' + response.message + "</smal>";
          }
         
          setTimeout(function () {
            This.passwordMsg = "";
          }, 3000);
        },
        (error) => {
          this.passwordMsg =
            '<small class="text-danger"> Something went wrong. Feel free to contact us .</smal>';
        }
      );
    } else {
      this.getErrors(this.passwordForm);
    }
  }

  getErrors(FormAttr) {
    Object.keys(FormAttr.controls).forEach((key) => {
      console.log(key);
      const controlErrors: ValidationErrors = FormAttr.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  getBookinfo(id: string, index: number) {
    this.loader = true;
    //  const url = 'bookingdetails?username=javatest&password=123456&bookingCode=' + id;
    //console.log(url);
    // this.service.online_service(url).subscribe(response => {
    //   this.loader = false;
    //    console.log(response);
    //    if (response.code === '1') {
    //      this.detailedError = undefined;
    //     this.bookDetails[index].bookinfoData = response;
    //     if (this.bookDetails[index].bookinfoData.passengerDetails instanceof Array !== true) {
    //       this.bookDetails[index].bookinfoData.passengerDetails = new Array(this.bookDetails[index].bookinfoData.passengerDetails);
    //     }
    //    } else {
    //     this.detailedError = '<small class="text-danger">' + response.errorDesc + '</small>';
    //    }
    // }, (error) => {
    //   console.log(error);
    // });
    // const postData = {'bookingId': id};
    this.funcName = "bookingdetails";
    this.service.post_data(this.funcName, id).subscribe(
      (response) => {
        this.loader = false;
        console.log("res", response.data);
        if (response.status === "success") {
          this.detailedError = undefined;
          this.bookDetails[index].bookinfoData = response.data;
          if (
            this.bookDetails[index].bookinfoData.passengerDetails instanceof
              Array !==
            true
          ) {
            this.bookDetails[index].bookinfoData.passengerDetails = new Array(
              this.bookDetails[index].bookinfoData.passengerDetails
            );
          }
          this.ratingShow = this.bookDetails[index].bookinfoData.ratingShow;
          console.log("bookDetails", this.bookDetails[index]);
            const cus_rating = this.bookDetails[index].bookinfoData.customer_rating;
  
            this.items_rating = [];

    this.items_rating = [
      {  'id': index,
      'rating': this.bookDetails[index].bookinfoData.customer_rating,
      'bookingId':this.bookDetails[index].bookingId,
      'journeyDate':this.bookDetails[index].travelDate, }]
    console.log('items_rating', this.items_rating[0])
  
  
        } else {
          this.detailedError =
            '<small class="text-danger">' + response.message + "</small>";
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  modelOpen(template: TemplateRef<any>) {
    console.log("called");
    this.modalRef = this.bsmodalservice.show(template, { backdrop: "static" });
  }

  close() {
    this.modalRef.hide();
  }

  addFund() {
    const This = this;
    console.log(this.amount);
    if (this.amount !== undefined) {
      if (!isNaN(parseInt(this.amount, 10))) {
        this.amount_error = undefined;
        console.log(this.amount);
        this.close();
        const options = {
         'key': 'rzp_live_KBTt75APHMzR4I',
        //  key: "rzp_test_PpOinqLyVBHOcP",
          amount: this.amount * 100, // 2000 paise = INR 20 this.amount
          name: this.loginDetails.name,
          description: "Ticket Robo Add fund:",
          image: "./../../../assets/imgs/logo.png",
          handler: function (response) {
            console.log(response);
            This.payment_success(response.razorpay_payment_id);
          },
          prefill: {
            name: this.loginDetails.name,
            email: this.loginDetails.username,
          },
          notes: {
            address: "Ticket Robo",
          },
          theme: {
            color: "#d91f36",
          },
        };
        const rzp1 = new Razorpay(options);
        rzp1.open();
      } else {
        this.amount_error = "Input valid amount";
      }
    } else {
      this.amount_error = "Amount required";
    }
    console.log(this.amount);
  }

  payment_success(pay_id: string) {
    this.loading = true;
    const postData = {
      userId: this.loginDetails.id,
      amount: this.amount,
      token: pay_id,
    };
    this.funcName = "addFund";
    this.service.post_data(this.funcName, postData).subscribe(
      (responseData) => {
        this.loading = false;
        if (responseData.status === "success") {
          this.walletAmount();
           window.location.reload();
        } else {
          console.log("failed");
        }
      },
      (error) => {
        this.loading = false;
      }
    );
  }

  walletAmount() {
    this.loading = true;
    const postData = { userId: this.loginDetails.id };
    this.funcName = "walletAmount";
    this.service.post_data(this.funcName, postData).subscribe(
      (responseData) => {
        this.loading = false;
        if (responseData.status === "success") {
          this.walletData = responseData.data;
          console.log(this.walletData);
        } else {
          console.log("failed");
        }
      },
      (error) => {
        this.loading = false;
      }
    );
    this.service.post_data("getCreditlimit", postData).subscribe(
      
      (responseData) => {
        if (responseData.status === "success") {
          this.creditBalance = responseData.data;
         
        } else {
          console.log("failed");
        }
      },
      (error) => {}
    );
  }

  bookReplace(bookID: any) {
    const str = bookID;
    return str;
  }
  ratingComponentClick(clickObj: any): void {
    console.log('hererating');
    const item = this.items.find(((i: any) => i.id === clickObj.itemId));

    if (!!item) {
      item.rating = clickObj.rating;
      this.ratingClicked = clickObj.rating;
     
    }
    this.loading = true;
    const postData = { user_id: this.loginDetails.id ,bookingId:item.bookingId,rating:item.rating};
    this.funcName = "add_rating";
    this.service.post_data(this.funcName, postData).subscribe(
      (responseData) => {
        this.loading = false;
    
      },
      (error) => {
        this.loading = false;
      }
    );
}
ratingComponentClick1(clickObj: any): void {
  console.log('bookingId',this.items_rating);
  console.log('bookingId',clickObj);
  console.log('diya-test',clickObj.itemId);
  console.log('rating',clickObj.rating);
 
 
  // const item_rating = this.items_rating.find(((i: any) => i.id === clickObj.itemId));



  // if (!!item_rating) {
  //   item_rating.rating = clickObj.rating;
  //   this.ratingClicked = clickObj.rating;
   
  // }
  //console.log('heres',item_rating)
  this.loading = true;
  // const postData = { user_id: this.loginDetails.id ,bookingId:item_rating.bookingId,rating:item_rating.rating};
  const postData = { user_id: this.loginDetails.id ,bookingId:this.items_rating[0].bookingId,rating:clickObj.rating};
  this.funcName = "add_rating";
  this.service.post_data(this.funcName, postData).subscribe(
    (responseData) => {
      this.loading = false;
      this.goToPage('account');
      window.location.reload();
  
    },
    (error) => {
      this.loading = false;
    }
  );
}

numSequence(n: number): Array<number> { 
  console.log('n-val',Array(n))
      return Array(n); 
    } 
    bookReport(){

      let fromdate=null;
      let todate=null;
      fromdate=this.FromDateBookingReport;
      todate=this.ToDateBookingReport;
      if(fromdate != null && fromdate > todate){
        alert("From date should be greater than To date");
        return;
      }
      this.loading = true;
      const postData = { user_id: this.loginDetails.id,page:this.current_booking_report,fromDate:fromdate,toDate:todate};
      this.funcName = "booking_report";
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          this.loading = false;
          if (responseData.status === "success" && responseData.data.length > 0) {
            if(fromdate != null ){ 
            this.bookReportDetails =[];
            }
            this.bookReportDetails = this.bookReportDetails.concat(responseData.data);
            ++this.current_booking_report;
         
          } else {
            if(fromdate != null){ 
            this.bookReportDetails =[];
            }
            this.bookReportDetails =
              this.bookReportDetails.length > 0 ? this.bookReportDetails : [];
          }
          console.log('bookReportDetails',this.bookReportDetails)
      
        },
        (error) => {
          this.loading = false;
        }
      );

    }
    onScrollBookReport() {
      console.log("scrolled down-book-report!!");
      let fromdate=this.FromDateBookingReport;
      let todate=this.ToDateBookingReport;
      if(fromdate == null && todate == null){
        this.bookReport();
      }
       
    }
    onScrollPayment(){
      console.log("scrolled down-payment-report!!");
      let fromdate=this.FromDatePaymentReport;
      let todate=this.ToDatePaymentReport;
      if(fromdate == null && todate == null){
        this.PaymentReport();
      }
       
    }
    onScrollDailyReport(){ 
      console.log("scrolled down-daily-report!!");
       let fromdate=this.FromDateDailyReport;
       let todate=this.ToDateDailyReport;
       if(fromdate == null && todate == null){
        this.dailyReport();
      }
       
    }
    exportBookingReport():void {
      let fromdate=null;
      let todate=null;
      fromdate=this.FromDateBookingReport;
      todate=this.ToDateBookingReport;
      this.loading = true;
      this.funcName ="exportBookingReport";
      const postData = { user_id: this.loginDetails.id,fromDate:fromdate,toDate:todate,type:"export"};
     
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          this.loading = false;
          if (responseData.status === "success" && responseData.data.length > 0) {
            //this.bookReportDetails = this.bookReportDetails.concat(responseData.data);
            //this.excelService.exportAsExcelFile(this.bookReportDetails, 'Booking-Report');
            this.excelService.exportAsExcelFile(responseData.data, 'Booking-Report');
         
          } else {
            // this.bookReportDetails =
            //   this.bookReportDetails.length > 0 ? this.bookReportDetails : [];
          }
          console.log('bookReportDetails',this.bookReportDetails)
      
        },
        (error) => {
          this.loading = false;
        }
      );
    }
    dailyReport(){
      let fromdate=null;
      let todate=null;
      fromdate=this.FromDateDailyReport;
      todate=this.ToDateDailyReport;
      if(fromdate != null && fromdate > todate){
        alert("From date should be greater than To date");
        return;
      }

      this.loading = true;
      const postData = { user_id: this.loginDetails.id,page:this.current_daily_report,fromDate:fromdate,toDate:todate};
      this.funcName = "daily_report";
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          this.loading = false;
          if (responseData.status === "success" && responseData.data.length > 0) {
            if(fromdate != null ){ 
              this.DailyReportDetails =[];
              }
            this.DailyReportDetails = this.DailyReportDetails.concat(responseData.data);
            ++this.current_daily_report;
         
          } else {
            if(fromdate != null){ 
              this.DailyReportDetails =[];
              }
            this.DailyReportDetails =
              this.DailyReportDetails.length > 0 ? this.DailyReportDetails : [];
          }
          console.log('DailyReportDetails',this.DailyReportDetails)
      
        },
        (error) => {
          this.loading = false;
        }
      );

    }
    exportDailyReport():void {
      this.loading = true;
      this.funcName ="exportDAilyReport";
      let fromdate=null;
      let todate=null;
      fromdate=this.FromDateDailyReport;
      todate=this.ToDateDailyReport;
      const postData = { user_id: this.loginDetails.id,fromDate:fromdate,toDate:todate,type:"export"};
      
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          this.loading = false;
          if (responseData.status === "success" && responseData.data.length > 0) {
            //this.DailyReportDetails = this.DailyReportDetails.concat(responseData.data);
            this.excelService.exportAsExcelFile(responseData.data, 'Daily-Earning-Report');
         
          } else {
            //this.DailyReportDetails =
             // this.DailyReportDetails.length > 0 ? this.DailyReportDetails : [];
          }
          console.log('DailyReportDetails',this.DailyReportDetails)
      
        },
        (error) => {
          this.loading = false;
        }
      );
    }
    PaymentReport(){
      let fromdate=null;
      let todate=null;
      fromdate=this.FromDatePaymentReport;
      todate=this.ToDatePaymentReport;
      if(fromdate != null && fromdate > todate){
        alert("From date should be greater than To date");
        return;
      }

      this.loading = true;
      const postData = { user_id: this.loginDetails.id,page:this.current_payment_report,fromdate:fromdate,todate:todate};
      this.funcName = "payment_report";
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          this.loading = false;
          if (responseData.status === "success" && responseData.data.length > 0) {
            if(fromdate != null ){ 
              this.PaymentyReportDetails =[];
              }
            this.PaymentyReportDetails = this.PaymentyReportDetails.concat(responseData.data);
            ++this.current_payment_report;
         
          } else {
            if(fromdate != null ){ 
              this.PaymentyReportDetails =[];
              }
            this.PaymentyReportDetails =
              this.PaymentyReportDetails.length > 0 ? this.PaymentyReportDetails : [];
          }
          console.log('PaymrntReportDetails',this.PaymentyReportDetails)
      
        },
        (error) => {
          this.loading = false;
        }
      );

    }
    exportPaymentReport():void {
      this.loading = true;
      this.funcName ="exportPaymentReport";
      let fromdate=null;
      let todate=null;
      fromdate=this.FromDatePaymentReport;
      todate=this.ToDatePaymentReport;
      const postData = { user_id: this.loginDetails.id,type:"export",fromdate:fromdate,todate:todate};
      
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          this.loading = false;
          if (responseData.status === "success" && responseData.data.length > 0) {
            
            this.excelService.exportAsExcelFile(responseData.data, 'Agent-Payment-Report');
         
          } else {
           
          }
          console.log('DailyReportDetails',this.PaymentyReportDetails)
      
        },
        (error) => {
          this.loading = false;
        }
      );
    }
    date_filter(){
      let fromdate=this.FromDateBookingReport;
      let todate=this.ToDateBookingReport;
      if(fromdate > todate){
        alert("From date should be greater than To date");
        return;
      }
      this.bookReport()

    }
    offlineRecharge() { 
      const This = this;

      let valid1 =  false;
      let valid2 = false;
      let valid3 = false;
      let valid4 = false;
      if (this.recharge_amount !== undefined) {
        
       
        if (!isNaN(parseInt(this.recharge_amount, 10))) {
          this.recharge_amount_error = undefined;
          valid1 = true;
        }
        else {
          this.recharge_amount_error = "Input valid amount";
        }
      } else {
        this.recharge_amount_error = "Amount required";
      }
      if (this.recharge_type !== undefined && this.recharge_type!== "" ) {
        this.recharge_type_error = undefined;
        valid2 = true;
      }
      else{
        this.recharge_type_error ="Please Enter Payment Type"
      }
      if (this.recharge_transaction_id !== undefined && this.recharge_transaction_id !== "") {
        this.recharge_transaction_id_error = undefined;
        valid3 = true;
      }
      else{
        this.recharge_transaction_id_error ="Please Enter Transaction ID"
      }
      if(this.recharge_date == undefined ||  this.recharge_date == "Invalid Date"  || this.recharge_date == "" ){
       
        this.recharge_date_error ="Please Enter Transaction Date"
      }
      else{
        this.recharge_date_error = undefined;
        valid4 = true;
      }
      console.log('recharge_date',this.recharge_date);
   
      if(valid1 == true && valid2 == true && valid3 == true && valid4 == true){
        this.loading = true;
        this.funcName ="ofline_recharge_request";
        const postData = { user_id: this.loginDetails.id,amount:this.recharge_amount,mode:this.recharge_type,transId:this.recharge_transaction_id,transDate:this.recharge_date};
        
        this.service.post_data(this.funcName, postData).subscribe(
          (responseData) => {
            this.loading = false;
            if (responseData.status === "success") {
              this.loading = false;
              alert("Offline Recharge Request send successfully ");
           
            } else {
             
            }
            console.log('DailyReportDetails',this.PaymentyReportDetails)
        
          },
          (error) => {
            this.loading = false;
          }
        );
      }
      }

      date_filter_payment(){
        let fromdate=this.FromDatePaymentReport;
        let todate=this.ToDatePaymentReport;
        if(fromdate > todate){
          alert("From date should be greater than To date");
          return;
        }
        this.PaymentReport()
  
      }
      date_filter_daily(){
        let fromdate=this.FromDateDailyReport;
        let todate=this.ToDateDailyReport;
        if(fromdate > todate){
          alert("From date should be greater than To date");
          return;
        }
        this.dailyReport()
  
      }
}
