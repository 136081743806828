import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeModule} from './home/home.module';
import { AppComponent } from './app.component';
import {AppRoutingModule} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

import { AuthService } from './provider/auth.service';
import { AuthGuard } from './provider/auth.guard';
import { HttpErrorHandler, HandleError } from './provider/http-error-handler.service';
import { WebService } from './provider/web.service';
import { MessageService } from './provider/message.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SocialLoginModule, AuthServiceConfig,  GoogleLoginProvider,  FacebookLoginProvider } from 'angular-6-social-login-v2';
import { ExcelService } from './provider/excel.service';





import { ModalModule } from 'ngx-bootstrap';


import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';


defineLocale('de', deLocale);

export function getAuthServiceConfigs() {
  const config = new AuthServiceConfig(
      [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('1956236594672582')
        },
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider('32068576598-a8mjj1o5hgiverkh95290nac5ni8qp64.apps.googleusercontent.com')
        }
      ]);
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent
  
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HomeModule,
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    ModalModule.forRoot(),
    SocialLoginModule
  
  
  ],
  // tslint:disable-next-line:max-line-length
  providers: [AuthGuard, AuthService,ExcelService, HttpErrorHandler, WebService, MessageService, {provide: LocationStrategy, useClass: HashLocationStrategy}, {provide: AuthServiceConfig, useFactory: getAuthServiceConfigs}],
  bootstrap: [AppComponent],

})
export class AppModule { }
