import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WebService } from './../../provider/web.service';


@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  bookingId: any;
  funcName: any;
  bookinfoData: any;
  amount: any;
  bookLoad: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: WebService
  ) {
  }

  ngOnInit() {
    this.bookingId = this.route.snapshot.params['bookId'];
    this.amount = this.route.snapshot.params['amount'];
    this.getBookinfo(this.bookingId);
    
  }

  getBookinfo(bookId) {
    const url = 'bookingdetails?username=javatest&password=123456&bookingCode=' + bookId;
    // console.log(url);
    // this.service.online_service(url).subscribe(response => {
    //     this.bookinfoData = response;
    //    console.log(this.bookinfoData);
    // }, (error) => {
    //   console.log(error);
    // });
    this.funcName = 'bookingdetails';
    this.service.post_data(this.funcName, bookId).subscribe(responseData => {
      this.bookLoad = false;
      if ( responseData.status === 'success') {
        this.bookinfoData = responseData.data;
        console.log('res',responseData.data);
      } else {
        console.log('else-failed');
      }
    }, (error) => {
      this.bookLoad = false;
    });
  }

  navigate(type: string) {  console.log('navigate');
    if (type === 'home') {
      this.router.navigate(['/index']);
    } else {
      this.router.navigate(['/account']);
    }
  }

  getSeats(passanger: any, type: string) {
    if (passanger instanceof Array !== true) {
      passanger = new Array(passanger);
    }

    const seatArray = new Array();
    passanger.forEach(item => {
      // seatArray.push(item.seatNbr);
      seatArray.push(item.seatName);
    }); 

    return seatArray.join(', ');

  }

  bookReplace(bookID: any) {
    const str = bookID;
    return str;
  }

}
