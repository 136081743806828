import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, {queryParams: data});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

}
