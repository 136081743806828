
import { Component, OnInit, HostListener, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { FormControl, FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ValidationService } from './../provider/validation.service';
import { WebService } from './../provider/web.service';
import { SubjectService } from './../provider/subject.service';
import { Login } from '../provider/declare.model';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider, VkontakteLoginProvider} from 'angular-6-social-login-v2';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import {requireCheckboxesToBeCheckedValidator}   from './../provider/require-checkboxes-to-be-checked.validator';

declare var $;
declare const window: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})


export class NavbarComponent implements OnInit {

modalRef: BsModalRef;
signForm: FormGroup;
forgotForm: FormGroup;
forgotFormAgent: FormGroup;
loginForm: FormGroup;
agentForm: FormGroup;
printForm: FormGroup;
cancelForm: FormGroup;
@ViewChild('login') private login: TemplateRef<HTMLElement>;

mobnumPattern = '^((\\+?)|0)?[0-9]{0,20}$';
emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

email_id = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)]);
password = new FormControl('', [Validators.required, Validators.minLength(6)]);
name = new FormControl('', [Validators.required]);
mob = new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(this.mobnumPattern)]);
term = new FormControl('', [Validators.required, Validators.pattern('true')]);
loading: boolean;
loginSubmit: boolean;
agentSubmit: boolean;
SignSubmit: boolean;
printSubmit: boolean;
funcName: string;
loginDetails: any;
loginResponse: any;
agentResponse: any;
signResponse: any;
socialResponse: any;
forgotResponse: any;
printResponse: any;
cancelResponse: any;
forgotSubmit: any;
cancelSubmit: any;
userNotexist:any;
agentNotexist:any;
allSeatData:any;
selectedSeat: any[];
totalBaseFare:any;
constructor(
 
  private router: Router,
  private route: ActivatedRoute,
  private bsmodalservice: BsModalService,
  private formBuilder: FormBuilder,
  public vs: ValidationService,
  public service: WebService,
  public subjectService: SubjectService,
  private socialAuthService: AuthService
 
  ) {
    this.loading = false;
    this.loginSubmit = false;
    this.SignSubmit = false;
    this.forgotSubmit = false;
    this.printSubmit = false;
    this.cancelSubmit = false;
  }

  ngOnInit() {
    let userId = '';
    this.selectedSeat = new Array();
    this.totalBaseFare =0;
    this.subjectService.getLoginData().subscribe(loginData => {
      this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));
      if (this.loginDetails) {
        userId = this.loginDetails.id;
      }
    });

    this.subjectService.getisLoggined().subscribe(isLoggined => {
      console.log(isLoggined);
      if (isLoggined != null) {
        if (isLoggined === 'not_loggedIn') {
          this.open(this.login, 'login');
        }
      }
   });

   this.printForm = this.formBuilder.group({
     'type': ['email'],
    'bookingID': ['', Validators.compose([Validators.required])],
   
    });

    this.cancelForm = this.formBuilder.group({
      // 'email': this.email_id,
      'email': ['', Validators.compose([Validators.required])],
      'bookingID': ['', Validators.compose([Validators.required])],
     // 'seatNos': ['', Validators.compose([Validators.required])],
      'userId': [userId],
 
     });

  }



  @HostListener('window:scroll', [])
  onWindowScroll() {

    const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > 100) {
      console.log('You are 100px from the top to bottom');
    } else if (number > 500) {
        console.log('You are 500px from the top to bottom');
    }

    $(window).scroll(function() {
      const sticky = $('.navbar'),
        scroll = $(window).scrollTop();
        if (scroll > 10) { sticky.addClass('scrolled_navbar'); } else { sticky.removeClass('scrolled_navbar'); }
    });
  }

   goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, {queryParams: data});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  form_initalize(type: string) {
    switch (type) {
      case 'login':
        this.loginForm = this.formBuilder.group({
          username: this.email_id,
          password: this.password
        });
      break;
      case 'signup':
        this.signForm = this.formBuilder.group({
          username: this.email_id,
          password: this.password,
          name: this.name,
          mob: this.mob,
          terms: this.term,
        });
      break;
      case 'forgot':
        this.forgotForm = this.formBuilder.group({
          username: this.email_id
        });
      break;
      case 'agent':
        this.agentForm = this.formBuilder.group({
          username: this.email_id,
          password: this.password
        });
        case 'forgot_agent':
          this.forgotFormAgent = this.formBuilder.group({
            username: this.email_id
          });
        break;
      break;
    }

    if (this.forgotForm) {
      this.forgotForm.reset();
    } 
    if (this.forgotFormAgent) {
      this.forgotFormAgent.reset();
    }else if (this.signForm) {
      this.signForm.reset();
    } else if (this.agentForm) {
      this.agentForm.reset();
    } else {
      this.loginForm.reset();
    }
  }

  open(template: TemplateRef<any>, type: string) {
    console.log('calles');
    this.form_initalize(type);
    this.modalRef = this.bsmodalservice.show(template, {backdrop: 'static'});
  }

  modelOpen(template: TemplateRef<any>) {
  
      this.printForm.reset();
      this.cancelForm.reset();
    
    this.modalRef = this.bsmodalservice.show(template, {backdrop: 'static'});
  }

  close() {
    this.modalRef.hide();
    this.userNotexist ="0";
    this.agentNotexist="0";
    this.cancelSubmit =undefined;
    this.printSubmit=undefined;
    this.totalBaseFare =0;
    this.selectedSeat =new Array();
    this.allSeatData=undefined;
  }

  loginProcess() {
    const This = this;
    console.log(this.loginForm.value);
    this.loginSubmit = true;
    if (this.loginForm.valid) {
      const userObj = this.loginForm.value;
      this.funcName = 'user_login';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          response.data.type = 'user';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
          this.loginResponse = response;
          setTimeout( function() {
            This.loginResponse = undefined;
            This.loginForm.reset();
            This.close();
          }, 1000);
        } else {
          this.loginResponse = response;
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.loginForm);
    }
    console.log('tested');
  }

  signProcess() {
    const This = this;
    console.log(this.signForm.value);
    this.SignSubmit = true;
    if (this.signForm.valid) {
      const userObj = this.signForm.value;
      this.funcName = 'user_signup';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          response.data.type = 'user';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
          this.signResponse = response;
          setTimeout( function() {
            This.signResponse = undefined;
            This.signForm.reset();
            This.close();
          }, 1000);
        } else {
          this.signResponse = response;
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.signForm);
    }
    console.log('tested');
  }

  forgotProcess() {
    const This = this;
    console.log(this.forgotForm.value);
    this.forgotSubmit = true;
    if (this.forgotForm.valid) {
      const userObj = this.forgotForm.value;
      this.funcName = 'user_forgot';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          this.loginResponse = response;
          This.forgotForm.reset();
          this.modalRef.hide();
          this.open(this.login, 'login');
          setTimeout( function() {
            This.loginResponse.status = '';
            This.loginResponse.message = '';
            This.forgotSubmit = false;
            This.loginResponse = undefined;
          }, 2000);
        } else {
          this.forgotResponse = response;
          this.userNotexist ="1";
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.signForm);
    }
  }

  printProcess() {
    const This = this;
    this.printSubmit = true;
    if (this.printForm.valid) {
      const printObj = this.printForm.value;
      printObj.bookingID = this.bookrevReplace(printObj.bookingID);
      this.funcName = 'print_ticket';
      this.loading = true;
      this.service.post_data(this.funcName, printObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          this.printResponse = response;
          setTimeout( function() {
            This.printForm.reset();
            This.modalRef.hide();
            This.printResponse.status = '';
            This.printResponse.message = '';
            This.printSubmit = false;
            This.printResponse = undefined;
          }, 1000);
        } else {
          this.printResponse = response;
          setTimeout( function() {
            This.printForm.reset();
            This.modalRef.hide();
            This.printResponse.status = '';
            This.printResponse.message = '';
            This.printSubmit = false;
            This.printResponse = undefined;
           
          }, 1000);
      
        }
      }, (error) => {

      });
    }
  }

  agentProcess() {
    const This = this;
    console.log(this.agentForm.value);
    this.agentSubmit = true;
    if (this.agentForm.valid) {
      const userObj = this.agentForm.value;
      this.funcName = 'agent_login';
      this.loading = true;
      this.service.post_data(this.funcName, userObj).subscribe(response => {
        this.loading = false;
        if ( response.status === 'success') {
          response.data.type = 'agent';
          this.service.setLocalStorageItem('userData', JSON.stringify(response.data));
          this.subjectService.sendLoginData('logged_in');
          this.agentResponse = response;
          setTimeout( function() {
            This.agentResponse = undefined;
            This.agentForm.reset();
            This.close();
          }, 1000);
        } else {
          this.agentResponse = response;
        }
      }, (error) => {

      });
    } else {
      this.loading = false;
      this.getErrors(this.loginForm);
    }
    console.log('tested');
  }

  cancelProcess() { 
  
    if (typeof this.allSeatData == 'undefined') {
      const This = this;
      this.cancelSubmit = true;
      if (this.cancelForm.valid) { 
        const cancelObj = this.cancelForm.value;
        
        cancelObj.bookingID = this.bookrevReplace(cancelObj.bookingID);
        cancelObj['type'] = this.loginDetails.type;
        cancelObj['userId'] = this.loginDetails.id;
        
       
        this.loading = true;
        this.service.post_data("get_seat_names", cancelObj).subscribe(response => {
          this.loading = false;
          if ( response.status === 'success') {
          cancelObj.seatNos = response.data;
          This.allSeatData= response.data;
         
      
          } else {
            this.cancelResponse = response;
            setTimeout( function() {
              This.cancelResponse = undefined;
              This.cancelForm.reset();
              This.close();
            }, 1000);
          }
        }, (error) => {
          this.cancelResponse = 'Something Went wrong';
        });
      }
    }else{
        if (this.cancelForm.valid) { 
          const This = this;
          
          if(this.selectedSeat.length !=0){
            if(confirm("Do you Really Want to cancel this ticket")){
              const cancelObj = this.cancelForm.value;
            
              cancelObj.bookingID = this.bookrevReplace(cancelObj.bookingID);
              cancelObj['type'] = this.loginDetails.type;
              cancelObj['userId'] = this.loginDetails.id;
              
              cancelObj.seatNos = this.selectedSeat.join();
              this.loading = true;
              this.funcName = 'cancel_ticket';
              this.service.post_data(this.funcName, cancelObj).subscribe(response => {
                this.loading = false;
                if ( response.status === 'success') {
                  this.cancelResponse = response;
                 this.cancelTicket(response.data);
                } else {
                 
                  this.cancelResponse = response;
                  setTimeout( function() {
                    This.cancelResponse = undefined;
                    This.allSeatData= undefined;
                    This.cancelForm.reset();
                    This.close();
                  }, 1000);
                  
                }
              }, (error) => {
                this.cancelResponse = 'Something Went wrong';
              });
            }else{
              return;
            }

          }else{
            alert("Please choose the seats you want to cancel")
          }
   
      
    
    
  
    } else {
      this.cancelResponse = 'Form Invalid';
    }


    }
   
  
   //test end 

  
   
   
  }

  cancelTicket(data: any) {
   
    const postData = data;
    console.log('postData',postData);
    this.loading = true;
    let url = 'ticket/cancel/'+ postData.bookingCode+'?username=javatest&password=123456&functionName=cancel_booking';
    
    var ticketCode=postData.bookingCode;
   
    this.service.online_service(url, 'data=' + JSON.stringify(ticketCode)).subscribe(response => {
        const confirm_result = response;
        
        if (confirm_result.status === 1) { 
         
          url = 'ticket/cancel/'+ postData.bookingCode+'/confirm?seatCodeList='+postData.cancelSeats_request.join()+'?username=javatest&password=123456&functionName=confirm_cancel_booking';
          this.service.online_service(url, 'data=' + JSON.stringify(postData)).subscribe(res => {
            if (res.status === 1 ) {
              console.log('enter into cancel page');
            res.message ="Your Ticket has been cancelled successfully";
            this.funcName = 'confirmCancel';
            this.loading = true;
            const confirmData = {'bookingId': postData.bookingCode,'seats':postData.cancelSeats_request,'cancellationCharge':res.data.cancellationCharge,'totalRefundAmount':res.data.totalRefundAmount,'cancelCode':res.data.code};
            this.service.post_data(this.funcName, confirmData).subscribe(responseData => {
            this.cancelForm.reset();
            this.close();
           
            console.log('here',postData.cancelSeats.join())
            if ( responseData.status === 'success') {
              this.loading = false;
              alert(responseData.message)
              this.cancelSubmit = false;
              this.cancelForm.reset();
              this.close();
              this.goToPage('account');
              window.location.reload();
 
            } else {
              this.errorResp(responseData.message);
            }
          }, (error) => {
            this.errorResp('Something went wrong');
            this.allSeatData=undefined;
          });
            } else {
              res.message = res.errorDesc;
              this.errorResp(res.errorDesc);
            }
          }, (error) => {
            this.errorResp('Something went wrong. Please feel free to contact');
            this.allSeatData=undefined;
          });
  
        }else{
          this.errorResp(confirm_result.errorDesc + ' Cancel Request failed. Please feel free to contact');
          this.allSeatData=undefined;
          this.selectedSeat = new Array();
          this.totalBaseFare =0;
        }
    }, (error) => {
      this.errorResp('Cancel Request failed. please try agin later');
      this.allSeatData=undefined;
      this.selectedSeat = new Array();
          this.totalBaseFare =0;
    });
  }

  errorResp(message: string) {
    this.cancelResponse.status = 'error';
    this.cancelResponse.message = message;
    const This = this;
    setTimeout( function() {
      This.cancelForm.reset();
      This.modalRef.hide();
      This.cancelResponse.status = '';
      This.cancelResponse.message = '';
      This.cancelSubmit = false;
      This.cancelResponse = undefined;
    }, 2000);
  }

  getErrors(FormAttr) {
    Object.keys(FormAttr.controls).forEach(key => {
      console.log(key);
      const controlErrors: ValidationErrors = FormAttr.get(key).errors;
      if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
    });
  }

  logout() {
    const userData = this.service.getLocalStorageItem('userData');
    this.service.removeLocalStorageItem(userData);
    this.subjectService.sendLoginData('logged_out');
    this.router.navigate(['../index']);
  }

  public socialSignIn(socialPlatform: string) {
    let socialPlatformProvider: any;
    const This = this;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }

    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.funcName = 'social_login';
          this.loading = true;
          this.service.post_data(this.funcName, userData).subscribe(responseData => {
            this.loading = false;
            if ( responseData.status === 'success') {
              responseData.data.type = 'user';
              this.service.setLocalStorageItem('userData', JSON.stringify(responseData.data));
              this.subjectService.sendLoginData('logged_in');
              this.socialResponse = responseData;
              This.close();
            } else {
              this.errorResp(responseData.message);
            }
          }, (error) => {
            this.errorResp('Something went wrong');
          });
      }).catch(function (data) {       
      // Handle error here
      this.errorResp(data);
      });
  }

  bookrevReplace(bookID: any) {
    const str = bookID;
    return str;
  }

  seatChecked(event, index, item) {
    item.checked = !item.checked;
    if(item.checked == true){
      this.selectedSeat.push(item.seatNumber)
      this.totalBaseFare = parseInt(this.totalBaseFare)+parseInt(item.baseFare);
    }else{
      this.selectedSeat.forEach((element,index)=>{
        if(element==item.seatNumber) {
          this.selectedSeat.splice(index,1);
       
        this.totalBaseFare = parseInt(this.totalBaseFare)-parseInt(item.baseFare);
          
        }
     });
    }
   console.log('totalBaseFare',this.totalBaseFare );
   console.log('selectedSeat',this.selectedSeat.length);
}
forgotProcessAgent() {
  const This = this;
  console.log(this.forgotFormAgent.value);
  this.forgotSubmit = true;
  if (this.forgotFormAgent.valid) {
    const userObj = this.forgotFormAgent.value;
    this.funcName = 'agent_forgot';
    this.loading = true;
    this.service.post_data(this.funcName, userObj).subscribe(response => {
      this.loading = false;
      if ( response.status === 'success') {
        this.loginResponse = response;
        This.forgotFormAgent.reset();
        this.modalRef.hide();
        this.open(this.login, 'login');
        setTimeout( function() {
          This.loginResponse.status = '';
          This.loginResponse.message = '';
          This.forgotSubmit = false;
          This.loginResponse = undefined;
        }, 2000);
      } else {
        this.forgotResponse = response;
        this.agentNotexist ="1";
      }
    }, (error) => {

    });
  } else {
    this.loading = false;
    this.getErrors(this.signForm);
  }
}

}
