import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ValidationService } from './../../provider/validation.service';
import { WebService } from './../../provider/web.service';

import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, switchMap, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  source_field: any;
  homeForm: FormGroup;
  results: any[];
  funcName: any;
  desfuncName: any;
  searchSubmit: boolean;
  sourceFlag: boolean;
  destResults: any[];
  destFlag: boolean;
  main_error: boolean;
  today: any;
  source_state: boolean;
  dest_state: boolean;
  sourceLoad: boolean;
  destload: boolean;
  promocodedata:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    public vs: ValidationService,
    public service: WebService,
    ) {
    this._localeService.use('engb');
    this.homeForm = this.formBuilder.group({
      fromStationId: ['', Validators.compose([Validators.required])],
      toStationId: ['', Validators.compose([Validators.required])],
      travelDate: ['', Validators.compose([Validators.required])],
      returnDate: [''],
      sourceName: ['', Validators.compose([Validators.required])],
      destinationName: ['', Validators.compose([Validators.required])],
    });
    this.searchSubmit = false;
    this.sourceFlag = false;
    this.main_error = false;
    this.source_state = false;
    this.dest_state = false;
    this.sourceLoad = false;
    this.destload = false;
    this.destFlag = false;
    this.today = new Date();
  }

  ngOnInit() {
    this.service.get_data('get_promocode').subscribe(response=> {
      if(response.status === "success") {      
         this.promocodedata = this.service.ObjArray(response.data);
      }
      console.log('Promocodedata', this.promocodedata);
    })
    this.funcName = 'source_location/';
    this.homeForm.get('sourceName').valueChanges.pipe(debounceTime(400), distinctUntilChanged(), switchMap((query) =>
    this.service.get_data(this.funcName +  this.filterQuery(query, 'source')))).subscribe(response => {
      console.log(this.source_state);
      this.sourceLoad = false;
      if (response.status === 'success' && this.source_state === true) {
        this.results = response.data;
        console.log(this.results);
        if (this.results.length > 0 ) {
          this.sourceFlag = true;
        } else {
          this.sourceFlag = false;
        }
        console.log(this.sourceFlag);
      } else {
        this.results = [];
        this.sourceFlag = false;
      }
    });

    this.desfuncName = 'destination_location/';
    this.homeForm.get('destinationName').valueChanges.pipe(debounceTime(500), distinctUntilChanged(), switchMap((query) =>
    this.service.get_data(this.desfuncName + this.filterQuery(query, 'dest') + '/' + this.homeForm.controls['fromStationId'].value)))
    .subscribe(response => {
      this.destload = false;
      if (response.status === 'success' && this.dest_state === true) {
        this.destResults = response.data;
        console.log(this.results);
        if (this.destResults.length > 0 ) {
          this.destFlag = true;
        } else {
          this.destFlag = false;
        }
        console.log(this.destFlag);
      } else {
        this.destResults = [];
        this.destFlag = false;
      }
    });

  }

  filterQuery(query, type: string) {
    if (type === 'source') {
      this.sourceLoad = true;
    } else {
      this.destload = true;
    }
    if (query !== null){
      return query.replace(/[^a-zA-Z ]/g, '');
    } else {
      return '';
    }
  }

  switch_loc() {
    const inter_source =   this.homeForm.controls['sourceName'].value;
    const inter_source_id = this.homeForm.controls['fromStationId'].value;
    const inter_destination =   this.homeForm.controls['destinationName'].value;
    const inter_destination_id = this.homeForm.controls['toStationId'].value;

    this.homeForm.controls['sourceName'].patchValue(inter_destination);
    this.homeForm.controls['fromStationId'].patchValue(inter_destination_id);
    this.homeForm.controls['destinationName'].patchValue(inter_source);
    this.homeForm.controls['toStationId'].patchValue(inter_source_id);
    console.log(this.homeForm.value);
   // this.homeForm = this.homeForm.value;
  }

  select_source(data: any) {
    this.sourceFlag = false;
    this.source_state = false;
    this.homeForm.controls['sourceName'].patchValue(data.stationName);
    this.homeForm.controls['fromStationId'].patchValue(data.stationCode);
  }

  select_dest(data: any) {
    this.destFlag = false;
    this.dest_state = false;
    this.homeForm.controls['destinationName'].patchValue(data.stationName);
    this.homeForm.controls['toStationId'].patchValue(data.stationCode);
  }

  onChanges(): void {
  }

  bus_search() {
    this.main_error = false;
    this.searchSubmit = true;
    const response = this.homeForm.value;
    const This = this;
    console.log(this.homeForm.value);
    if (this.homeForm.valid) {
      const searchObj = encodeURIComponent(JSON.stringify(this.homeForm.value));
      this.router.navigate(['searchresult/' + searchObj]);
    } else { 
      const data = this.homeForm.value;
      if (data.sourceName !== '' && data.destinationName !== '' && data.travelDate !== '') {
        if (data.fromStationId === '' || data.toStationId === '') {
          this.main_error = true;
        }
      }
    }
    console.log('tested');
  }

  initCalled() {
    this.sourceFlag = false;
    this.destFlag = false;
    console.log(this.source_state);
  }

  resetFun() {
    this.searchSubmit = false;
    this.homeForm.reset();
  }


 goToPage(path: any, data= null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  min_return () {
    const d = this.homeForm.get('travelDate').value;
    return d;
  }

  value_change(type: string) {
    console.log(this.source_state);
    if (type === 'source') {
      this.source_state = true;
    } else {
      this.dest_state = true;
    }
  }
}
