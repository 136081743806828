
import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ValidationService } from "./../../provider/validation.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { WebService } from "./../../provider/web.service";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { Location } from '@angular/common';

declare var Razorpay: any;

@Component({
  selector: "app-booking",
  templateUrl: "./booking.component.html",
  styleUrls: ["./booking.component.scss"],
})
export class BookingComponent implements OnInit {
  bookData: any;
  totalAmount: number;
  public passengerForm: FormGroup;
  bookSubmit: boolean;
  mobnumPattern = "^((\\+?)|0)?[0-9]{0,20}$";
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  modalRef: BsModalRef;
  book_result: any;
  bookFare: any;
  loginDetails: any;
  bookingId: any;
  @ViewChild("payment") private login: TemplateRef<HTMLElement>;
  promocode: any;
  funcName: any;
  loading: boolean;
  promoMsg: any;
  booked_error: any;
  bookLoad: boolean;
  promoLoad: boolean;
  walletData: any;
  gstResponse: any;
  creditBalance:any;
  tds:any;
  commission:any;
  gstVal:any;
  seatComm:any;
  seatTds:any;
  transactionFee:any = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public vs: ValidationService,
    private location: Location,
    private bsmodalservice: BsModalService,
    private service: WebService
  ) {
    this.bookSubmit = false;
    this.loading = false;
    this.promoLoad = false;
    this.funcName = "getGst";
    this.loginDetails = JSON.parse(
      this.service.getLocalStorageItem("userData")
      
    );
    this.service.post_data(this.funcName, null).subscribe(
      (responseData) => {
        if (responseData.status === "success") {
          this.gstResponse = responseData.data;
          this.bookFare = this.fareCharge(this.bookData.passenger);
          console.log('bookData',this.bookData);
        } else {
          console.log("failed");
        }
      },
      (error) => {}
    );
    const postData = { userId: this.loginDetails.id };
    this.service.post_data("getCreditlimit", postData).subscribe(
      
      (responseData) => {
        if (responseData.status === "success") {
          this.creditBalance = responseData.data;
         
        } else {
          console.log("failed");
        }
      },
      (error) => {}
    );
  }

  ngOnInit() {
    const bookData = decodeURIComponent(
      atob(this.route.snapshot.params["data"])
    );

    this.bookData = JSON.parse(bookData);

    this.totalAmount = this.total_amount(this.bookData);

    this.passengerForm = this.fb.group({
      // tslint:disable-next-line:max-line-length
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(15),
          Validators.pattern(this.mobnumPattern),
        ]),
      ],
      // tslint:disable-next-line:max-line-length
      email: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(this.emailPattern),
        ]),
      ],
      passenger: this.fb.array([]),
    });
    console.log("passengerForm", this.passengerForm);
    this.patchValues();
  
 
    console.log("loginDetails", this.loginDetails);
    if (this.loginDetails.type === "agent") {
      this.loginDetails.mob = this.loginDetails.phone;
      this.loginDetails.username = this.loginDetails.email_id;
      const postData = { userId: this.loginDetails.id };
      this.funcName = "walletAmount";
      this.service.post_data(this.funcName, postData).subscribe(
        (responseData) => {
          if (responseData.status === "success") {
            this.walletData = responseData.data;
          } else {
            console.log("failed");
          }
        },
        (error) => {}
      );
    }
  }

  total_amount(data: any) {
    // tslint:disable-next-line:radix
    const total_fare = this.bookData.passenger
      .map((item) => item.seatFare)
      .reduce((prev, next) => parseInt(prev) + parseInt(next));
    return total_fare;
  }

  patchValues() {
    const tds_percentage =5;
    const passengerCtrl = <FormArray>this.passengerForm.controls["passenger"];
    const passengerCount = this.bookData.passenger.length;
    let passenger_details;
    for (let i = 0; i < passengerCount; i++) {
      if (this.loginDetails.type === "agent") {
        this.seatComm = ((this.bookData.passenger[i].seatFare*this.bookData.operatorCommission)/100).toFixed(2);
        this.seatTds = Math.round(this.seatComm*tds_percentage/100);
      }else{
        this.seatComm =0;
        this.seatTds =0;
      }
      const gender =
        this.bookData.passenger[i].seatStatus === "F" ? "Female" : "Male";
       passenger_details = {
        seatName: this.bookData.passenger[i].seatName,
        seatCode: this.bookData.passenger[i].code,
        seatFare: this.bookData.passenger[i].seatFare,
        seatCommission:this.seatComm,
        seatTds:this.seatTds,
       

        name: [null, Validators.compose([Validators.required])],
        age: [
          null,
          Validators.compose([
            Validators.required,
            Validators.min(3),
            Validators.max(100),
          ]),
        ],
        gender: [gender, Validators.compose([Validators.required])],
      };
      passengerCtrl.push(this.fb.group(passenger_details));
    }
    console.log('bookdata',this.bookData);return;
  }

  booking(model: any, template: TemplateRef<any>) {
    this.bookSubmit = true;
    const This = this;
  
    if (this.passengerForm.valid) {
      if (this.loginDetails.type === "agent") {   

        var walletWithCredit = parseFloat(this.creditBalance)+parseFloat(this.walletData.amount);
        console.log('walletWithCredit',walletWithCredit);
        console.log('this.bookFare.total',this.bookFare.total);

        if (this.walletData.amount < this.bookFare.total) {
          if(walletWithCredit< this.bookFare.total){
            this.booked_error =
            '<small class="text-danger">Insufficent Balance. Please recharge your account</small>';
            return false;
          }
          else{ 
            this.booked_error =
            '<small class="text-danger">Due to insufficent Balance amount taken from your credit. it will be deducted when you recharge your account next time</small>';
          }
        
        }
      }
      this.bookLoad = true;
      const pass_data = this.passengerForm.value;
      //console.log('passinger',this.passengerForm.value);return;
      this.bookData.droppingPointCode =
        this.bookData.droppingPointCode !== ""
          ? this.bookData.droppingPointCode
          : "0000";
      this.bookData.email = pass_data.email;
      this.bookData.mobile = pass_data.phone;

      var i;
      for (i = 0; i < pass_data.passenger.length; i++) {
        pass_data.passenger[i].passengerName = pass_data.passenger[i]["name"];
        pass_data.passenger[i].passengerAge = pass_data.passenger[i]["age"];
        if (pass_data.passenger[i]["gender"] == "Male") {
          pass_data.passenger[i].passengerGendar = "M";
        } else {
          pass_data.passenger[i].passengerGendar = "F";
        }

        delete pass_data.passenger[i]["name"];
        delete pass_data.passenger[i]["age"];
        delete pass_data.passenger[i]["gender"];
      }
      console.log('this.bookData.droppingPointCode',this.bookData.droppingPointCode)
      if(this.bookData.droppingPointCode == '0000' ){ 
        this.bookData.droppingPointCode = this.bookData.toStationCode;
      }
      const postData = {
        boardingPoint: { code: this.bookData.boardingPointCode },
      
        droppingPoint: { code: this.bookData.droppingPointCode },
        travelDate: this.bookData.travelDate,
        ticketDetails: pass_data.passenger,
        fromStation: { code: this.bookData.fromStationCode },
        tripCode: this.bookData.tripCode,
        toStation: { code: this.bookData.toStationCode },
        passengerEmail: pass_data.email,
        passengerMobile: pass_data.phone,
        agentTransactionPNR: "GRM103DE",
      };
      console.log('this1',postData)
      const url =
        "ticket/blocking?username=javatest&password=123456&functionName=seatBlock";

      this.service.online_service(url, JSON.stringify(postData)).subscribe(
        (response) => {
          this.bookLoad = false;
          this.book_result = response;

          if (response.status === 1) {
            this.bookingId = response.data.ticketCode;
            this.bookFare = this.fareCharge(this.bookData.passenger);
            if (this.loginDetails.type === "agent") {
              this.bookFare.payment = "wallet";
            }
            this.modalRef = this.bsmodalservice.show(template, {
              backdrop: "static",
            });
          } else {
            if(response.errorDesc == 'Invalid passender name or empty')
            {
              alert('If you wait 10-15 mins. to skip the hold-time , the seats will again become available or  try another seat');
              this.router.navigate(['/index']); 
              return false;
            }
            this.booked_error =
              '<small class="text-danger">' + response.errorDesc + "</smal>";
            setTimeout(function () {
              This.booked_error = "";
            }, 3000);
          }
          console.log(this.book_result);
        },
        (error) => {
          console.log(error);
        }
      );
    }
    // this.payment();
    return;
  }

  close() {
    this.modalRef.hide();
  }

  paymentFun() {
    let  amount  = parseFloat(this.bookFare.total).toFixed(2);
    const amounts = +amount * 100;
    this.bookLoad = true;
    const This = this;
    //console.log('here',this.bookFare.payment);return;
    this.close();
    if (this.bookFare.payment === "cash") {
      this.success();
    } else if (this.bookFare.payment === "online") {
      const options = {
        'key': 'rzp_live_KBTt75APHMzR4I',
        // key: "rzp_test_vnzE8XQnvr7xk9",    
        amount: amounts , // 2000 paise = INR 20 this.bookFare.total this.bookFare.total
        name: this.loginDetails.name,
        description: "Ticket Robo Seat Booking. Booking ID:" + this.bookingId,
        image: "./../../../assets/imgs/logo.png",
        payment_capture: 1,
        handler: function (response,error) {
          This.success(response.razorpay_payment_id);
          if(error){
          alert(error);
        }
        },   
        "modal": {
          "ondismiss": function(){
            if (window.confirm("If you cancel payment you have to wait 10 min to skip the hold-time , the seats will again become available or  try another seat!") == true) {
            this.router.navigate(['/index']); 
            This.bookLoad = false;
            } else {
              This.bookLoad = false;
              This.paymentAgain();
            }
              // window.location.replace("//put your redirect URL");
           }
      },
        prefill: {
          name: this.loginDetails.name,
          email: this.loginDetails.username,
        },
        notes: {
          address: "Ticket Robo",
        },
        theme: {
          color: "#d91f36",
        },
      };   
      
 
      const rzp1 = new Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', function (response){
       // alert(response.error.code);
        alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
});
     // Razorpay.open(options, successCallback, cancelCallback)

    } else {
      this.success();
    }
    this.bookLoad = false;
  }

  paymentAgain() {
    let  amount  = parseFloat(this.bookFare.total).toFixed(2);
    const amounts = +amount * 100;
    this.bookLoad = true;
    const This = this;
    //console.log('here',this.bookFare.payment);return;
    this.close();
    if (this.bookFare.payment === "cash") {
      this.success();
    } else if (this.bookFare.payment === "online") {
      const options = {
        'key': 'rzp_live_KBTt75APHMzR4I',
        // key: "rzp_test_vnzE8XQnvr7xk9",    
        amount: amounts , // 2000 paise = INR 20 this.bookFare.total this.bookFare.total
        name: this.loginDetails.name,
        description: "Ticket Robo Seat Booking. Booking ID:" + this.bookingId,
        image: "./../../../assets/imgs/logo.png",
        payment_capture: 1,
        handler: function (response,error) {
          This.success(response.razorpay_payment_id);
          if(error){
          alert(error);
        }
        },   
        "modal": {
          "ondismiss": function(){
            if (window.confirm("If you cancel payment you have to wait 10 min to skip the hold-time , the seats will again become available or  try another seat!") == true) {
            This.router.navigate(['/index']); 
            This.bookLoad = false;
            } else {
              This.bookLoad = false;
              This.paymentFun();
            }
              // window.location.replace("//put your redirect URL");
           }
      },
        prefill: {
          name: this.loginDetails.name,
          email: this.loginDetails.username,
        },
        notes: {
          address: "Ticket Robo",
        },
        theme: {
          color: "#d91f36",
        },
      };   
      
 
      const rzp1 = new Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', function (response){
       // alert(response.error.code);
        alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
});
     // Razorpay.open(options, successCallback, cancelCallback)

    } else {
      this.success();
    }
    this.bookLoad = false;
  }

  fareCharge(passenger: any) {
    let gst = 0;
    // tslint:disable-next-line:radix
    const sum = passenger
      .map((item) => item.seatFare)
      .reduce((prev, next) => parseInt(prev) + parseInt(next));

      // this.transactionFee =    passenger
   
      // .map((item) => (parseFloat(item.seatFare)+parseFloat(item.serviceTax)) *2.5/100)
    
      // .reduce((prev, next) => parseFloat(prev) + parseFloat(next));
      this.transactionFee = 0;

   
    // tslint:disable-next-line:radix
    const ServiceTax = passenger
      .map((item) => item.serviceTax)
      .reduce((prev, next) => parseInt(prev) + parseInt(next));
    // tslint:disable-next-line:radix
    // const ServiceCharge = passenger.map(item => item.operatorServiceCharge).reduce((prev, next) => parseInt(prev) + parseInt(next));
    const ServiceCharge = "0";
      if (this.loginDetails.type === "agent") {
   this.commission = passenger
   
    .map((item) => item.seatFare *this.bookData.operatorCommission/100)
  
    .reduce((prev, next) => parseInt(prev) + parseInt(next));
     const tds_percentage = 5;

    this.tds =Math.round(this.commission*tds_percentage/100);
    
 
  }else{
    const commission =0;
    this.tds =0;
  }
    // tslint:disable-next-line:radix
  //   if (this.loginDetails.type === "agent") {
  //  this.commission = passenger
   
  //   .map((item) => item.seatFare *this.bookData.operatorCommission/100)
  
  //   .reduce((prev, next) => parseInt(prev) + parseInt(next));
  //    const tds_percentage = 5;

  //   this.tds =Math.round(this.commission*tds_percentage/100);
 
  //   var total =
  //     parseFloat(sum) + parseFloat(ServiceTax) + parseFloat(ServiceCharge)- parseFloat(this.commission)+parseFloat(this.tds);
  //   const index = this.bookData.busType.includes("Non");
  //   if (index !== true) {
  //     gst = Math.round((total * this.gstResponse) / 100);
  //     total = Math.round(total + gst);
  //   } 
  //   }else{
  //     const commission =0;
  //     this.tds =0;
  //     var total =
  //     parseFloat(sum) + parseFloat(ServiceTax) + parseFloat(ServiceCharge);
  //   const index = this.bookData.busType.includes("Non");
  //   if (index !== true) {
  //     gst = Math.round((total * this.gstResponse) / 100);
  //     total = Math.round(total + gst);
  //   }

  //   }
     
      var total =
      parseFloat(sum) + parseFloat(ServiceTax) + parseFloat(this.transactionFee);
     // const index = this.bookData.busType.includes("Non");
    //   if (index !== true) {
    //   gst = Math.round((total * this.gstResponse) / 100);
    //   total = Math.round(total + gst);
    // }
    this.gstVal =gst;
    // tslint:disable-next-line:max-line-length
   
    const res = {
      sum: sum,
      ServiceTax: ServiceTax,
      ServiceCharge: ServiceCharge,
      total: total,
      discount: 0,
      gst: gst,
      payment: "online",
      commission: this.commission,
      tds:this.tds,
      transactionFee:this.transactionFee
    };
    console.log('res',this.bookData.busType)
    return res;
  }

  paymentType(type: any) {
    console.log(type);
    // this.bookFare.payment = type;
  }

  success(transId = null) {
   // console.log('suc',this.bookFare);return;  
    this.bookLoad = true;
    this.bookData.fare = this.bookFare;
    this.bookData.passenger = this.passengerForm.value.passenger;
    this.bookData.bookingId = this.bookingId;
    this.bookData.transId = transId;
    this.bookData.paymentType = this.bookFare.payment;
    this.bookData.promocode = this.promocode;
    this.bookData.userId = this.loginDetails.id;
    this.bookData.usertype = this.loginDetails.type;
    this.bookData.gst = this.gstVal;

    const ticketcode = this.bookingId;
    const postData = {
      ticketCode: this.bookingId,
      referenceTicketNumber: this.bookingId,
    };

    const url =
      "ticket/confirm/" +
      ticketcode +
      "?username=javatest&password=123456&functionName=confirm_booking";

    this.service
      .online_service(url, "data=" + JSON.stringify(postData))
      .subscribe(
        (response) => {
          const confirm_result = response;

          const passinger_details = this.passengerForm.value;

          if (confirm_result.status === 1) {
            // console.log('passinger_details.passinger',passinger_details.passenger)
            console.log('passinger_details',passinger_details)
            // console.log("passinger_details['passinger']",passinger_details['passenger'])
            const passenger = passinger_details.passenger;
            this.bookData.passenger = passenger;
            this.bookData.pnr = confirm_result.data.operatorPNR;
            // if (passenger instanceof Array !== true) {
            //   this.bookData.passenger[0].pnr = passenger.operatorPnr;
            // } else {
            //   this.bookData.passenger.forEach(function (item: any, i: any) {
            //     item[i].pnr = passenger[i].operatorPnr;
            //   });

            // }

            console.log("book123", this.bookData);
            this.funcName = "bookseat";
            this.service.post_data(this.funcName, this.bookData).subscribe(
              (responseData) => {
                this.bookLoad = false;
                if (responseData.status === "success") {
                  this.router.navigate(
                    ["/success", this.bookingId, this.bookFare.total],
                    { replaceUrl: true }
                  );
                } else {
                  console.log("else-failed");
                }
              },
              (error) => {
                this.bookLoad = false;
              }
            );
          } else {
            this.bookLoad = false;
          }
        },
        (error) => {
          this.bookLoad = false;
          console.log(error);
        }
      );
  }

  check_promo(promovalue) {
    this.promoLoad = true;
    console.log(promovalue.value);
    const sendData = { userId: this.loginDetails.id, code: promovalue.value };
    const This = this;
    this.funcName = "check_promo";
    this.service.post_data(this.funcName, sendData).subscribe(
      (response) => {
        this.promoLoad = false;
        if (response.status === "success") {
          this.promoMsg =
            '<small class="text-info">' + response.message + "</smal>";
          let offer = (this.bookFare.sum * response.data.rate) / 100;
          offer =
            offer > response.data.max_limit ? response.data.max_limit : offer;
          this.bookFare.discount = offer;
          // if (this.loginDetails.type === "agent") {
          //   this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst) -parseFloat(this.bookFare.commission) +parseFloat(this.bookFare.tds) - offer;
          // }else{
          //   this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst)  - offer;
          // }
       //   this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst) +parseFloat(this.bookFare.transactionFee) - offer;

          this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst) - offer;
          //this.bookFare.total = this.bookFare.total - offer;
          this.promocode = response.data.id;
          console.log('this.bookFare',this.bookFare);
        } else {
          this.promoMsg =
            '<small class="text-danger">' + response.message + "</small>";
          this.bookFare.discount = 0;
          this.promocode = 0;
          // if (this.loginDetails.type === "agent") {
          //   this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst) -parseFloat(this.bookFare.commission) +parseFloat(this.bookFare.tds) ;
          // }else{
          //   this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst) ;
          // }
          this.bookFare.total = parseFloat(this.bookFare.sum) +parseFloat(this.bookFare.ServiceCharge) +parseFloat(this.bookFare.ServiceTax)+parseFloat(this.bookFare.gst)+parseFloat(this.bookFare.transactionFee) ;
          console.log("failed");
        }
        setTimeout(function () {
          This.promoMsg = undefined;
        }, 3000);
      },
      (error) => {}
    );
  }

  getGender_state(index: any, type: any) {
    if (this.bookData.passenger[index].seatStatus.name === type) {
    return false;
    } else {
      return true;
    }
  }
}
