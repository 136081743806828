import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from './../../provider/validation.service';
import { WebService } from './../../provider/web.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { SubjectService } from './../../provider/subject.service';
import { Options } from 'ng5-slider';



@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.scss']
})
export class SearchresultComponent implements OnInit {
  searchData: any;
  noResult:any;
  editable: boolean;
  searchForm: FormGroup;
  searchSubmit: boolean;
  funcName: any;
  results: any[];
  sourceFlag: boolean;
  destFlag: boolean;
  main_error: boolean;
  desfuncName: any;
  destResults: any[];
  today: any;
  bus_result: any;
  bus_result_copy: any;
  filter_type: any;
  user_filter: any;
  service_status: boolean;
  selected_seats: any[];
  c_policy: any[];
  booking_selected: any[];
  book_type: any;
  boardingPoints: any;
  droppingPoints: any;
  loginDetails: any;
  source_state: boolean;
  dest_state: boolean;
  loading: boolean;
  seatLoad: boolean;
  customAmount: any[];
  noBusFound:any;

   value: number = 10;
  highValue: number = 60;
  options: Options = {
    floor: 0,
    ceil: 24
  };
  value2: number = 500;
  highValue2: number = 3500;
  options2: Options = {
    floor: 500,
    ceil: 3500
  };
  value3: number = 500;
  highValue3: number = 3500;
  options3: Options = {
    floor: 500,
    ceil: 3500
  };

   is_valid:any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public vs: ValidationService,
    public service: WebService,
    private _localeService: BsLocaleService,
    public subjectService: SubjectService
    ) {
    this._localeService.use('engb');
    this.editable = false;
    this.sourceFlag = false;
    this.destFlag = false;
    this.source_state = false;
    this.dest_state = false;
    this.loading = false;
    this.seatLoad = false;

    this.searchForm = this.formBuilder.group({
      fromStationId: ['', Validators.compose([Validators.required])],
      toStationId: ['', Validators.compose([Validators.required])],
      travelDate: ['', Validators.compose([Validators.required])],
      returnDate: [''],
      sourceName: ['', Validators.compose([Validators.required])],
      destinationName: ['', Validators.compose([Validators.required])],
    });

    this.searchSubmit = false;
    this.main_error = false;
    this.service_status = false;
    this.today = new Date();
    
    this.filter_type = {'travels': [], 'pick_point': [], 'drop_point': []};
    
    this.user_filter = {'travels': [], 'pick_point': [], 'drop_point': [], 'time': [], 'rate': [], 'type': [],'departure_start':'','departure_end':'','price_start':'','price_end':''};
    this.book_type = {
        'operatorCode': '',
        'scheduleCode': '',
        'travelDate': '',
        'fromStationCode': '',
        'toStationCode': '',
        'boardingPointCode': '',
        'droppingPointCode': '',
        'email': '',
        'mobile': '',
        'passenger': [],
        'basic': {'from': '', to: '', start_time: '', arrival: '', 'travels': '', 'board': '', 'drop': ''}
    };
    this.selected_seats = new Array();
    this.booking_selected = new Array();
    this.customAmount = [];
   
  }


  @Input() rating: number;
  @Input() itemId: number;
  @Output() ratingClick: EventEmitter<any> = new EventEmitter<any>();

  inputName: string;
  ngOnInit() {
    this.inputName = this.itemId + '_rating';
    const data = decodeURIComponent(this.route.snapshot.params['id']);
    console.log(data);
    this.searchData = JSON.parse(data);
    const travelDate = this.searchData.travelDate.split('T');
    this.searchData.travelDate = travelDate[0];
    console.log(this.searchData);
    let mydate = new Date(this.searchData.travelDate);
    console.log(mydate);
    this.searchForm.controls['sourceName'].patchValue(this.searchData.sourceName);
    this.searchForm.controls['fromStationId'].patchValue(this.searchData.fromStationId);
    this.searchForm.controls['destinationName'].patchValue(this.searchData.destinationName);
    this.searchForm.controls['toStationId'].patchValue(this.searchData.toStationId);
    this.searchForm.controls['travelDate'].patchValue(mydate);

    if (this.searchData.returnDate !== null && this.searchData.returnDate !== '') {
        const returnDate = this.searchData.returnDate.split('T');
        this.searchData.returnDate = returnDate[0];
        mydate = new Date(this.searchData.returnDate);
        this.searchForm.controls['returnDate'].patchValue(mydate);
    }

    this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));

    this.subjectService.getLoginData().subscribe(loginData => {
        this.loginDetails = JSON.parse(this.service.getLocalStorageItem('userData'));
    });
    this.onChange();
    this.get_search_bus();

   

  }

  onChange() {
    const funcNamed = 'source_location/';
    this.searchForm.get('sourceName').valueChanges.pipe(debounceTime(500), distinctUntilChanged(), switchMap((query) =>
    this.service.get_data(funcNamed + query.replace(/[^a-zA-Z ]/g, '')))).subscribe(response => {
      if (response.status === 'success' && this.source_state === true) {
        this.results = response.data;
        console.log(this.results);
        if (this.results.length > 0 ) {
       
          this.sourceFlag = true;
        } else {
          this.sourceFlag = false;
        }
        console.log(this.sourceFlag);
      } else {
        this.results = [];
        this.sourceFlag = false;
      }
    });

    this.desfuncName = 'destination_location/';
    this.searchForm.get('destinationName').valueChanges.pipe(debounceTime(500), distinctUntilChanged(), switchMap((query) =>
    this.service.get_data(this.desfuncName + query.replace(/[^a-zA-Z ]/g, '') + '/' + this.searchForm.controls['fromStationId'].value)))
    .subscribe(response => {
      if (response.status === 'success' && this.dest_state === true) {
        this.destResults = response.data;
        console.log(this.results);
        if (this.destResults.length > 0 ) {
          this.destFlag = true;
        } else {
          this.destFlag = false;
        }
        console.log(this.sourceFlag);
      } else {
        this.destResults = [];
        this.destFlag = false;
      }
    });
  }

  onClick(rating: number): void {
      console.log(rating);
    this.rating = rating;
    this.ratingClick.emit({
      itemId: this.itemId,
      rating: rating
    });
  }

  goToPage(path, data = null) {
    console.log(data);
    this.router.navigateByUrl(path, { queryParams: data});
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  modify() {
    this.editable = !this.editable;
  }

  duration(num:any) {
    const hours = Math.floor(num / 60);  
     const  minutes = num % 60;
     return hours + ":" + minutes;
  }

  bus_search() {

    console.log('heres',this.searchForm.value)
    this.main_error = false;
  
    this.searchSubmit = true;
    const response = this.searchForm.value;
    const This = this;
   
    if (this.searchForm.valid) {
      this.searchData = this.searchForm.value;
      this.searchData.travelDate = this.formatDate(this.searchData.travelDate);
      this.searchData.returnDate = this.searchData.returnDate !== '' ? this.formatDate(this.searchData.returnDate) : '';
      const searchObj = encodeURIComponent(JSON.stringify(this.searchForm.value));
     
      const url = 'searchresult/' + searchObj;
      this.router.navigateByUrl(url);
      this.editable = false;
    } else {
      const data = this.searchForm.value;
      if (data.sourceName !== '' && data.destinationName !== '' && data.travelDate !== '') {
        if (data.fromStationId === '' || data.toStationId === '') {
          this.main_error = true;
        }
      }
    }
  
    this.get_search_bus();
  }

  select_source(data: any) {
    this.source_state = false;
    this.sourceFlag = false;
    this.searchForm.controls['sourceName'].patchValue(data.stationName);
    this.searchForm.controls['fromStationId'].patchValue(data.stationCode);
  }

  select_dest(data: any) {
    this.destFlag = false;
    this.dest_state = false;
    this.searchForm.controls['destinationName'].patchValue(data.stationName);
    this.searchForm.controls['toStationId'].patchValue(data.stationCode);
  }

  switch_loc() {
    const inter_source =   this.searchForm.controls['sourceName'].value;
    const inter_source_id = this.searchForm.controls['fromStationId'].value;
    const inter_destination =   this.searchForm.controls['destinationName'].value;
    const inter_destination_id = this.searchForm.controls['toStationId'].value;

    this.searchForm.controls['sourceName'].patchValue(inter_destination);
    this.searchForm.controls['fromStationId'].patchValue(inter_destination_id);
    this.searchForm.controls['destinationName'].patchValue(inter_source);
    this.searchForm.controls['toStationId'].patchValue(inter_source_id);
    console.log(this.searchForm.value);
    this.searchData = this.searchForm.value;
  }

  change_date(type: string) {
    const curr_date = new Date().getTime();
    const set_date = new Date(this.searchData.travelDate);
    let choose_date;
    if (type === 'prev') {
      choose_date = set_date.setDate(set_date.getDate() - 1);
    } else {
      choose_date = set_date.setDate(set_date.getDate() + 1);
    }

    if (curr_date <= choose_date) {
      const d = new Date(choose_date).toISOString();
      const datechoose = d.split('T');
      console.log(datechoose[0]);
      this.searchData.travelDate = datechoose[0];
      const mydate = new Date(this.searchData.travelDate);
      this.searchForm.controls['travelDate'].patchValue(mydate);
    }
    this.get_search_bus();
  }

  get_search_bus() {
    const This = this;
    this.loading = true;
      this.service_status = false;
     

    this.funcName = 'customPrice';
    this.loading = true;
   
    // return;
    this.service.post_data(this.funcName, this.searchData).subscribe(response => {
        // this.loading = false;
        if ( response.status === 'success') {
            this.customAmount = response.data;
        } else {
            this.customAmount = [];
        }
    }, (error) => {
        console.log(error);
    });
    

    // tslint:disable-next-line:max-line-length
    const url = 'search?username=javatest&password=123456&travelDate=' + this.searchData.travelDate + '&fromStationCode=' + this.searchData.fromStationId + '&toStationCode=' + this.searchData.toStationId+'&functionName=trip_search';
   
    this.service.online_service(url).subscribe(response => {
        this.loading = false;
        this.bus_result = response;
        this.bus_result_copy = response;
        this.service_status = true;
       
       
        if (this.bus_result.status === 1) { 
      
             if (this.bus_result.data instanceof Array !== true) {
                this.bus_result.data = new Array(this.bus_result.data);
            }
            if(this.bus_result.data.length == 0){
              this.noBusFound = "no bus found";
            }else{
              this.noBusFound ="";
            }
            
            this.filter_result(this.bus_result.data);
            console.log('here',this.bus_result.data[0].operator)
        
             this.bus_result.data.forEach(function (el1,key) {
              // console.log('com',el1.operator.commission)
              // console.log('key',key)
              
              if (This.loginDetails.type === 'agent'){
                 if(parseFloat(This.loginDetails.discount ) > (parseFloat(el1.operator.commission ) )){
                 This.bus_result.data[key].operator.commission =This.loginDetails.discount
                 }
              }
              })
            
          
        } else {
            this.bus_result = {'searchResult': []};
            this.noBusFound = response.errorDesc;
            console.log('noBusFound',this.noBusFound)
        }
        console.log('res',this.bus_result.data);
    }, (error) => {
      console.log(error);
    });
  




  }

  filter_result(result) { 
    
    result.forEach(element => { 
     
      // if( this.filter_type.travels.id !=element.operator.code){
        this.filter_type.travels.push({'id': element.operator.code, 'name': element.operator.name});
      // }
    //  this.filter_type.amenities.push({'code': element.amenities.code, 'name': element.amenities.name,'flag':element.amenities.activeFlag});
    // element.amenities.forEach(item => {
    // this.filter_type.amenities.push({'id': item.code, 'name': item.name,'activeFlag':item.activeFlag});
    // console.log('item',item)
    //  });
     
      if (element.fromStation.stationPoint instanceof Array) {
        element.fromStation.stationPoint.forEach(item => {
         // if( this.filter_type.pick_point.id !=element.fromStation.stationPoint){
          this.filter_type.pick_point.push({'id': item.code, 'name': item.name});
         // }
        });
      } else {
        if (element.fromStation.stationPoint !== undefined) {
            this.filter_type.pick_point.push({'id': element.fromStation.code, 'name': element.fromStation.name});
        }
      }
    
      if (element.toStation.stationPoint instanceof Array) {
        
        element.toStation.stationPoint.forEach(item => {
         
            this.filter_type.drop_point.push({'id':item.code, 'name': item.name});
        });
      } else {
          if (element.toStation.stationPoint !== undefined) {
            this.filter_type.drop_point.push({'id': element.toStation.code, 'name': element.toStation.name});
          }
      }
     
   // element.rate = this.check_rating(element.ratings, 10);
   
      // tslint:disable-next-line:max-line-length
      //element.seat_layout = {'availableSeats': '', 'boardingPoints':  '', 'busMapStructure': '', 'code': '', 'columnsList': '', 'layerCount': 0, 'message': '', 'rowsList': ''};
    });
    this.filter_type.pick_point = this.removeDuplicates(this.filter_type.pick_point, 'name');
    this.filter_type.drop_point = this.removeDuplicates(this.filter_type.drop_point, 'name');
    this.filter_type.travels = this.removeDuplicates(this.filter_type.travels, 'id');

console.log('here',this.filter_type)
    
  }

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => {
            return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
        });
    }
    filter_range( key, value) {
    this.user_filter[key] =value;
    console.log('filter',this.user_filter);
    }

    choose_type(type: string, value, event) {
     
      
        let index;
        if (event.target.checked === true) {
            this.user_filter[type].push(value);
        } else {
            index = this.user_filter[type].indexOf(value);
            this.user_filter[type].splice(index, 1);
        }
        console.log('filter',this.user_filter);
        
    }

    check_rating(rating, rate) {
        let avg_count = 0;
        let bus_rate = 0;
        let punc_rate = 0;
        let staff_rate = 0;
        let total_rate = 0;
        if (rating.busQuality !== 'UNRATED') {
            avg_count += 1;
            bus_rate = this.rate_value(rating.busQuality);
        }

        if (rating.punctuality !== 'UNRATED') {
            avg_count += 1;
            punc_rate = this.rate_value(rating.punctuality);
        }

        if (rating.punctuality !== 'UNRATED') {
            avg_count += 1;
            staff_rate = this.rate_value(rating.staffBehavior);
        }

        total_rate = bus_rate + punc_rate + staff_rate;
        const avg_rate = Math.round(total_rate / avg_count);
        const avg_star = 5 / 3;
        return Math.round(avg_rate * avg_star);
    }

    rate_value(type: string) {
        switch (type) {
            case 'POOR':
                return 1;
            break;
            case 'AVERAGE':
                return 2;
            break;
            case 'GOOD':
                return 3;
            break;
            default:
                return 0;
        }
    }

    get_filter_journey(journey: string) {
        let journeyTime;
        journeyTime = journey.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [journey];
        journeyTime = journeyTime.slice (1);
        journeyTime.splice(3, 1);
        return journeyTime.join ('');
    }

    time_string(time_list: string) {
        let time_split: any;
        const date_list = time_list.split(' - ');
        const start_time = date_list[0];
        const end_time = date_list[1];
        time_split = start_time.split(':');
        const date2 = new Date();
        date2.setHours(time_split[0]);
        date2.setMinutes(time_split[1]);
        time_split = end_time.split(':');
        const date3 = new Date();
        date3.setHours(time_split[0]);
        date3.setMinutes(time_split[1]);
        return date2.getTime() + '-' + date3.getTime();
    }

    formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) { month = '0' + month; }
        if (day.length < 2) { day = '0' + day; }
        return [year, month, day].join('-');
    }

    min_return () {
        const d = this.searchForm.get('travelDate').value;
        return d;
    }

    base_fare(map_array, count, scheduleCode: any) {
     //console.log('here',map_array.data.bus.seatLayoutList);return;
       
        if (count > 0) {
            let array = [];
            if (count === 2) {
                array = map_array.data.bus.seatLayoutList.lower.concat(map_array.data.bus.seatLayoutList.upper);
            } else {
                array = map_array.data.bus.seatLayoutList.lower;
            }
           
            const flags = [], output = [], l = array.length;
            let i;
            for (i = 0; i < l; i++) {
          
                if (flags[array[i].seatFare]) {
                    continue;
                } else {
                    flags[array[i].seatFare] = true;
                    if ( array[i].seatFare !== '0.0' &&  array[i].seatFare !=='NA' ) {
                       const fareAmt = this.checkPrice(array[i].seatFare, scheduleCode);
                       output.push('&#8377;' + fareAmt);
                    }
                }
            }
            return output.join('-');
        } else {
            return 0;
        }
    }

    // get_seats(index_new: any, scheduleCode: string, operatorCode: string) {
      get_seats(index_new: any, scheduleCode: string, operatorCode: string) {
      
      
        const index = index_new;
        
       
        const tripcode = scheduleCode;
        this.seatLoad = true;
        this.booking_selected[index_new] = this.book_type;
        this.selected_seats[index_new] = new Array();
       
        const operatorData = this.bus_result.data[index_new];
        const url = 'busmap?username=javatest&password=123456&tripCode=' + tripcode+'&fromStationCode='+ this.searchData.fromStationId + '&toStationCode=' + this.searchData.toStationId +'&travelDate=' + this.searchData.travelDate +'&functionName=busMap';
        this.service.online_service(url).subscribe(response => {
          this.seatLoad = false;
          
          if (response.status === 1) {  
            this.bus_result.data[index].seatErrMsg = undefined;
            this.bus_result.data[index].seat_layout = response;
            
            this.bus_result.data[index].seat_layout['boardingPoints'] = this.service.ObjArray(response.boardingPoints);
            this.bus_result.data[index].seat_layout['droppingPoints'] = this.service.ObjArray(response.droppingPoints);
            this.bus_result.data[index].seat_layout['layout'] = this.seat_arrange(response);
            this.bus_result.data[index].seat_layout.layerCount =response.data.bus.seatLayoutList.layer;
            this.bus_result.data[index].seat_layout.availableSeats = response.data.availableSeatCount;
            console.log('index',this.bus_result.data);
          
        } else {
            this.bus_result.data[index].seatErrMsg  = '<small style="text-align:enter">' + response.errorDesc + '</small>';
        }
        
      }, (error) => {
        console.log(error);
      });
     
  
    }

    get_seat_layer(count: number) {
        return 12 / count;
    }

    seat_arrange(response) {
      let lower_rows: any, lower_column: any;
      let upper_rows: any, upper_column: any;
      const lower_seats = [];
      let upper_seats_rows = [];
      const upper_seats = [];
      let lower_seats_rows = [];
      let seats = [];
      seats =response.data.bus.seatLayoutList;

      lower_rows =response.data.bus.seatLayoutList.rows[0];
      lower_column =  response.data.bus.seatLayoutList.cols[0];
      console.log('lower_rows', response.data.bus.seatLayoutList.lower)

         for ( let i = 1; i <= lower_rows; i++) {
            lower_seats_rows = [];
            for (let j = 1; j <= lower_column; j++) {
              response.data.bus.seatLayoutList.lower.filter(seats => {
                    if (seats.rowPos == i && seats.colPos == j) {
                        //console.log('seats1',seats)
                        lower_seats_rows.push(seats);
                    }
                });
            }
            lower_seats.push(lower_seats_rows);
        }

console.log('seats',lower_seats)
 if(response.data.bus.seatLayoutList.layer == 2){ 

  upper_rows =response.data.bus.seatLayoutList.rows[1];
  upper_column =  response.data.bus.seatLayoutList.cols[1];

               for ( let i = 1; i <= upper_rows; i++) {
                upper_seats_rows = [];
                for (let j = 1; j <= upper_column; j++) {
                  response.data.bus.seatLayoutList.upper.filter(seats => {
                
                        if (seats.rowPos == i && seats.colPos == j) {
                       
                            upper_seats_rows.push(seats);
                        }
                    });
                }
                upper_seats.push(upper_seats_rows);
            }
             
 }



      const numList = {'lower_rows': response.data.bus.seatLayoutList.rows[0], 'lower_column': response.data.bus.seatLayoutList.cols[0], 'upper_rows':response.data.bus.seatLayoutList.rows[1], 'upper_column': response.data.bus.seatLayoutList.cols[1] };

      const seats_return = {'lower_seats' : lower_seats, 'upper_seats': upper_seats, 'numList': numList};
      return seats_return;

    //   const test = seats.forEach(function (el) {
    //     console.log('el',el)
    //    if(el.layer == 1){

    //     if(el.seatName == 2){
    //       console.log('el',el)
    //     }

 
        
    //     lower_seats.push(el)
    //     lower_rows++;
    //    }else if(el.layer == 2){
    //     upper_seats.push(el);
       
    //     upper_rows++;
    //    }
       
       
    // });

    // seat_arrange(response) {
    //     let lower_rows: any, lower_column: any;
    //     let upper_rows: any, upper_column: any;
    //     const lower_seats = [];
    //     let upper_seats_rows = [];
    //     const upper_seats = [];
    //     let lower_seats_rows = [];
    //     let seats = [];
    //     seats =response.data.bus.seatLayoutList;
    //     lower_rows =0;
    //     upper_rows =0;
    //     const test = seats.forEach(function (el) {
    //       console.log('el',el)
    //      if(el.busSeatType.code == "LSL"){
          
    //       lower_seats.push(el)
       
         
    //       lower_rows++;
    //      }else if(el.busSeatType.code == "USL"){
    //       upper_seats.push(el);
         
    //       upper_rows++;
    //      }
         
         
    //   });

      // const lower_seats = Object.keys(lower_seats1);
      // const upper_seats = Object.keys(upper_seats1);
     
  //  console.log('ls',lower_seats)
  //  console.log('us',upper_seats)
  //       return;
        // if (response.layerCount  === '2') {
        //     lower_rows = response.rowsList[0];
        //     lower_column = response.columnsList[0];

        //     upper_rows = response.rowsList[1];
        //     upper_column = response.columnsList[1];

        //     for ( let i = 1; i <= upper_rows; i++) {
        //         upper_seats_rows = [];
        //         for (let j = 1; j <= upper_column; j++) {
        //             response.busMapStructure.upper.filter(seats => {
        //                 if (seats.row == i && seats.column == j) {
        //                     upper_seats_rows.push(seats);
        //                 }
        //             });
        //         }
        //         upper_seats.push(upper_seats_rows);
        //     }

        // } else {
        //     lower_rows = response.rowsList;
        //     lower_column = response.columnsList;
        // }

        // console.log(lower_rows, lower_column);
        // for ( let i = 1; i <= lower_rows; i++) {
        //     lower_seats_rows = [];
        //     for (let j = 1; j <= lower_column; j++) {
        //         response.busMapStructure.lower.filter(seats => {
        //             if (seats.row == i && seats.column == j) {
        //                 lower_seats_rows.push(seats);
        //             }
        //         });
        //     }
        //     lower_seats.push(lower_seats_rows);
        // }

        // const numList = {'lower_rows': lower_rows, 'lower_column': lower_column, 'upper_rows': upper_rows, 'upper_column': upper_column };
        // const numList = {'lower_rows': 4, 'lower_column': 4, 'upper_rows': 4, 'upper_column': 4 };

        // const seats_return = {'lower_seats' : lower_seats, 'upper_seats': upper_seats, 'numList': numList};
        // return seats_return;

    }
    
    set_book(events:any,item: any, index: any, scheduleCode: any) {
     
     
    
      var obj = -1;
    
      if(this.selected_seats[index].length == 0){
        this.selected_seats[index].push(item);
       
      }else{
            this.selected_seats[index].forEach(function (el1,key) {
            if(el1.seatName == item.seatName){
              obj = key;
            }
            })
          
              if (obj === -1) { 
                const item_price = this.checkPrice(item.seatFare, scheduleCode);
                item.baseFare = item_price;
                const seat_price = this.checkPrice(item.seatFare, scheduleCode);
                item.seatFare = seat_price;
                      if (this.selected_seats[index].length < 6) {
                      this.selected_seats[index].push(item);
                     
                    
                      }else{
                        alert("The maximum number of seats that can be selected is 6  ");
                        events.preventDefault();
                        
                       
                      }
                      console.log( 'Pushed array values' , this.selected_seats[index]);
                      console.log('Seat count' , this.selected_seats[index].length);
              } else { 
              
                this.selected_seats[index].splice(obj, 1);
                
              }

      }
    
    
   }

    set_book_old(item: any, index: any, scheduleCode: any) {
      //console.log('here', this.selected_seats['items.seatName'].length );
        const obj = this.selected_seats[index].findIndex(items =>  items.seatName === item.seatName);
      
       
        if (obj === -1) {
            const item_price = this.checkPrice(item.seatFare, scheduleCode);
            item.baseFare = item_price;
            const seat_price = this.checkPrice(item.seatFare, scheduleCode);
            item.seatFare = seat_price;
             if (this.selected_seats[index].length < 6) {
                this.selected_seats[index].push(item);
             }
            console.log( 'Pushed array values' , this.selected_seats[index]);
          console.log('Seat count' , this.selected_seats[index].length);
        } else {
            this.selected_seats[index].splice(obj, 1);
        }
    }

    check_exist(seatName: any, index: any) {  
     

   
     
      const found = this.selected_seats[index].some(function (el: any) {
       
          return el.seatName === seatName;
      });
     
      if (found) { return true; }
     
  }

    book_details(type: string, index: any) {
     // console.log('book_details',this.selected_seats[index]);
      
 
    //return this.selected_seats[index].map(e => e.seatNumber).join(', ');
        // tslint:disable-next-line:max-line-length
        if (this.selected_seats[index] instanceof Array === true && typeof this.selected_seats[index] !== 'undefined' && this.selected_seats[index].length > 0) {
         
            if (type === 'seats') {   
              var test = this.selected_seats[index].map(e => e.seatName).join(', ');
             
                return   this.selected_seats[index].map(e => e.seatName).join(', ');
              
            } else if (type === 'seat_fare') {
                // tslint:disable-next-line:radix
    
                const sum = this.selected_seats[index].map(item => item.seatFare).reduce((prev, next) => parseInt(prev) + parseInt(next));
                console.log('testseat_fare',this.selected_seats[index])
                return sum;
            } else if (type === 'operator') {
              console.log( 'here',this.selected_seats[index])
                 const oper = this.selected_seats[index].map(item => item.serviceTax).reduce(
                     // tslint:disable-next-line:radix
                     (prev, next) => parseInt(prev) + parseInt(next));
                 return oper;
            } else {
                // tslint:disable-next-line:radix
                const oper = this.selected_seats[index].map(item => item.seatFare).reduce((prev, next) => parseInt(prev) + parseInt(next));
                return oper;
            }
        }
    }

    booking_seat(index_new: any) {
 
      const index = index_new;
     
        // const index = this.bus_result.searchResult.findIndex(items =>  items.scheduleCode === index_new);
        // console.log(this.bus_result.searchResult[index]);
        this.booking_selected[index_new].operatorCommission =  this.bus_result.data[index].operator.commission;
        this.booking_selected[index_new].operatorCode =  this.bus_result.data[index].operator.code;
        this.booking_selected[index_new].tripCode =  this.bus_result.data[index].tripCode;
        this.booking_selected[index_new].travelDate =  this.searchData.travelDate;
        this.booking_selected[index_new].fromStationCode =  this.searchData.fromStationId;
        this.booking_selected[index_new].toStationCode =  this.searchData.toStationId;
        this.booking_selected[index_new].passenger =  this.selected_seats[index];
        this.booking_selected[index_new].basic.from = this.searchData.sourceName;
        this.booking_selected[index_new].basic.to = this.searchData.destinationName;
        this.booking_selected[index_new].basic.start_time = this.bus_result.data[index].fromStation.dateTime;
        this.booking_selected[index_new].basic.arrival =  this.bus_result.data[index].toStation.dateTime;
        this.booking_selected[index_new].travels = this.bus_result.data[index].operator.name;
        this.booking_selected[index_new].busType =  this.bus_result.data[index].bus.busType;
        this.booking_selected[index_new].scheduleCode =  this.bus_result.data[index].tripCode;
        this.booking_selected[index_new].cancellationTerm =  this.bus_result.data[index].cancellationTerm;

        
       
        if (this.loginDetails) {
            // tslint:disable-next-line:max-line-length
            this.booking_selected[index_new].email =  this.loginDetails.username !== undefined ? this.loginDetails.username : this.loginDetails.email_id;
            this.booking_selected[index_new].mobile = this.loginDetails.mob !== undefined ? this.loginDetails.mob : this.loginDetails.phone;
            let book_data = encodeURIComponent(JSON.stringify(this.booking_selected[index_new]));
            book_data = btoa(book_data);
            console.log(book_data);
            const url = 'booking/' + book_data;
            this.router.navigateByUrl(url);
        } else {
            this.subjectService.sendisLoggined('not_loggedIn');
        }


       
        // this.booking_selected[index].
    }

    check_booking(index: any) {
      console.log('@checkbooking',index)
      // const obj = this.bus_result.data.findIndex(items =>  items.tripCode === index);
      const obj = index;
      if (this.selected_seats[index] instanceof Array === true && typeof this.selected_seats[index] !== 'undefined' && this.selected_seats[index].length > 0) {
       
        if (this.booking_selected[index].boardingPointCode !== '') {
          console.log('if',this.bus_result.data)
          console.log('obj',obj)
            if (this.bus_result.data[obj].seat_layout['droppingPoints']) {
                if (this.booking_selected[index].droppingPointCode !== '') {
                    this.is_valid=1;return false;
                } else {
                    this.is_valid=0;return true;
                }
            } else {
                this.is_valid=1;return false;
            }
        } else {
            this.is_valid=0;return true;
        }
    } else {console.log('if')
        this.is_valid=0;return true;
    }
   

    }
    
    choose_location(type: string, index: any, code: any) {
     
        const select_loc = code.selectedOptions[0].innerHTML;
        var boarding_time;
        var boarding_contact_no;
        var drop_time;
        if (type === 'board') {
            this.booking_selected[index].boardingPointCode = code.value;
            this.booking_selected[index].basic.board = select_loc;
            const fromStations = this.bus_result.data[index].fromStation.stationPoint;
            fromStations.forEach(function (el) {
            if( code.value == el.code){
              boarding_time  = el.dateTime;
              boarding_contact_no=el.number;
              }
          })
         
          this.booking_selected[index].boardTime=boarding_time;
          this.booking_selected[index].boardContact=boarding_contact_no;
        } else {
            this.booking_selected[index].droppingPointCode = code.value;
            this.booking_selected[index].basic.drop = select_loc;
            const toStations = this.bus_result.data[index].toStation.stationPoint;
            toStations.forEach(function (el) {
            if( code.value == el.code){
              drop_time  = el.dateTime;
              }
          })
          console.log('drop_time',drop_time)
          this.booking_selected[index].dropTime=drop_time;
        }
    }

    value_change(type: string) {
        console.log(this.source_state);
        if (type === 'source') {
          this.source_state = true;
        } else {
          this.dest_state = true;
        }
    }

    initCalled() {
        this.sourceFlag = false;
        this.destFlag = false;
        console.log(this.source_state);
    }

    getWidth(col: number){
       const col_width =  (100 / col);
       return col_width + '%';
    }

    getIds(type: string){
        if (type === 'SL' || type === 'USL' || type === 'LSL' || type === 'SLSL' || type === 'SUSL' ){
            return 'sleeper_button';
        } else if (type === 'ST' || type === 'PB' ||  type === 'SS') {
            return 'seat_button';
        } else {
            return '';
        }
    }

    checkPrice(amount: any, scheduleCode: any) {

   
        const This = this;
  
     
        let amtArray = amount.toString().split(',');
        const rate = this.customAmount.forEach(function (el) {
            if (el.scheduleId === scheduleCode) {
                amtArray = amtArray.map(function(val) {
                    console.log(val,el.amount);
                    const amt =  parseFloat(val) + parseFloat(el.amount);
                    return amt;
                });
            }
        });
        if (This.loginDetails) {
            amtArray = amtArray.map(function(val) {
                if (This.loginDetails.type === 'agent') { return val;
                    // const discount = ((val * This.loginDetails.discount) / 100);
                    // console.log(discount);
                    // return (val - discount).toFixed(2);
                } else {
                    return val;
                }
            });
        }
        return amtArray.join(', ');
      

    }
    cancel_policy(tripcode: any){
     
      this.bus_result.data.forEach(function (el1) {
      
      
       if(el1.tripCode == tripcode){ 
        el1.cancellationTerm.policyList.forEach(function (el2) {
          this.filter_type.travels.push({'id': el2.code, 'name': el2.name});
          
         })
         
        }
      })
      
      
    }
    checkDiscount(amount: any, scheduleCode: any,commission:any) {

   
      const This = this;

   
      let amtArray = amount.toString().split(',');
      console.log('commission',commission)
      console.log('amount-test',amtArray);
     
      if (This.loginDetails) {
          amtArray = amtArray.map(function(val) {
              if (This.loginDetails.type === 'agent') {
                  const comm = ((val * commission) / 100);
                  console.log(comm);
                  return (comm).toFixed(2);
              } 
          });
      }
      console.log('amtArray',amtArray)
      return amtArray.join(', ');
    

  }
  numSequence(n: number): Array<number> { 

    return Array(n); 
  } 

}
