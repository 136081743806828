import { ModuleWithProviders } from '@angular/core'
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import {SearchresultComponent} from './searchresult/searchresult.component';
import {BookingComponent} from './booking/booking.component';
import { AccountComponent } from './account/account.component';
import { SuccessComponent } from './success/success.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { AuthGuard } from './../provider/auth.guard';
import { TermsComponent } from './terms/terms.component';

const ModuleRoutes: Routes = [
    { path: 'index', component: IndexComponent },
    { path: 'searchresult/:id',	component: SearchresultComponent},
    { path: 'booking/:data', component: BookingComponent },
    { path: 'account',	component: AccountComponent, canActivate: [AuthGuard]},
    { path: 'success/:bookId/:amount', component: SuccessComponent, canActivate: [AuthGuard] },
    { path: 'changepassword/:type/:id', component: ChangepasswordComponent },
   { path: 'terms', component:TermsComponent}
];

export const moduleRouting: ModuleWithProviders = RouterModule.forChild(ModuleRoutes);

