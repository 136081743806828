import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './home/account/account.component';
import { AuthGuard } from './provider/auth.guard';

const appRoutes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full'},
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] }
 ];
@NgModule({
  imports: [ RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
