import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchPipe', pure: false })
export class SearchPipe implements PipeTransform {
  transform(result, filters ) {
    
    return result.filter(function(item) {
        let index;
        let time_state: any;
        let state = 1;
        if ( filters.travels.length !== 0 || filters.drop_point.length !== 0 || filters.pick_point.length !== 0
            || filters.time.length !== 0 || filters.type.length !== 0) {
                console.log('if');

            if (filters.travels.length !== 0) {
                state = 0;
                index = filters.travels.indexOf(item.operator.code);
                if (index !== -1) {
                    state = 1;
                }
            }

            if (filters.pick_point.length !== 0 && state === 1) {
                state = 0;
              
                if (item.fromStation.stationPoint instanceof Array) {
                    let pick_state = false;
                    item.fromStation.stationPoint.forEach((items: any) => {
                        index = filters.pick_point.indexOf(items.name);
                        console.log('index',index);
                        if (index !== -1) {
                            pick_state = true;
                        }
                    });

                    if ( pick_state !== false ) {
                        state = 1;
                    }
                } else {
                    if (item.fromStation.stationPoint !== undefined) {
                        index = filters.pick_point.indexOf(item.fromStation.stationPoint.name);
                        if (index !== -1) {
                            state = 1;
                        }
                    }
                }
            }

            if (filters.drop_point.length !== 0 && state === 1) {
                state = 0;
                if (item.toStation.stationPoint instanceof Array) {
                    let pick_state = false;
                    item.toStation.stationPoint.forEach((items: any) => {
                        index = filters.drop_point.indexOf(items.name);
                        if (index !== -1) {
                            pick_state = true;
                        }
                    });

                    if ( pick_state !== false ) {
                        state = 1;
                    }
                } else {
                    if (item.toStation.stationPoint !== undefined) {
                        index = filters.drop_point.indexOf(item.toStation.stationPoint.name);
                        if (index !== -1) {
                            state = 1;
                        }
                    }
                }
            }

            if (filters.time.length !== 0 && state === 1) {
                state = 0;
              
                const time_split1 = item.fromStation.dateTime.split(' ');
                const time_split = time_split1[1].split(':');
              
                const date1 = new Date();
                console.log('datetime',item.fromStation.dateTime)
                console.log('split',time_split)
                date1.setHours(time_split[0]);
                console.log('setHours',time_split[0])
                date1.setMinutes(time_split[1]);
                const bus_time = date1.getTime();
              
                console.log('bus_time',date1.getTime())
                const item_list = [];
                time_state = false;
                filters.time.filter(times => {
                     const date_list = times.split('-');
                    // tslint:disable-next-line:radix
                    const start_time = parseInt(date_list[0]);
                    // tslint:disable-next-line:radix
                    const end_time = parseInt(date_list[1]);
                    console.log('start_time',start_time)
                    console.log('bus_time',bus_time)
                    console.log('end_time',end_time)
                    if (start_time < bus_time && bus_time  < end_time) {
                        time_state = true;
                    }
                });

                if (time_state !== false) {
                    state = 1;
                }

            }


            if (filters.type.length !== 0 && state === 1) {
                state = 0;
                const str = item.bus.busType;
                let type_state = false;
                filters.type.forEach((items: any) => {
                    if (items === 'A/C' || items === 'Sleeper') {
                        if (items === 'A/C') {
                            index = str.includes('Non');
                            if (index !== true) {
                                type_state = true;
                            }
                        } else {
                            index = str.includes('Semi');
                            if (index !== true) {
                                type_state = true;
                            }
                        }
                    } else {
                        index = str.includes(items);
                        if (index === true) {
                            type_state = true;
                        }
                    }
                });

                if (type_state !== false) {
                    state = 1;
                }
            }


            if (state === 1) {
                return item;
            }


        } else {
            console.log('else')
            return item;
        }
    })
    return result;
  }
}
